import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GamesService {
  neverHaveIEver = [
    // {
    //   premise: '// https://www.gohen.com/planning-tips/never-have-i-ever-questions.asp',
    //   visited: false
    // },
    {
      premise: 'Never have I ever gone on a date with no intention of paying for anything',
      visited: false
    },
    {
      premise: 'Never have I ever met up with someone from a dating app',
      visited: false
    },
    {
      premise: 'Never have I ever slept with someone on the first date',
      visited: false
    },
    {
      premise: 'Never have I ever pretended to get a text to leave a date',
      visited: false
    },
    {
      premise: 'Never have I ever gone on a date just to make someone else jealous',
      visited: false
    },
    {
      premise: 'Never have I ever gone on more than one date in one day\n',
      visited: false
    },
    {
      premise: 'Never have I ever talked about my ex on a first date',
      visited: false
    },
    {
      premise: 'Never have I ever dated someone ten or over ten years older',
      visited: false
    },
    {
      premise: 'Never have I ever dated someone over ten years younger\n',
      visited: false
    },
    {
      premise: 'Never have I ever said I\'d call but never bothered',
      visited: false
    },
    {
      premise: 'Never have I ever dated more than one person at once\n',
      visited: false
    },
    {
      premise: 'Never have I ever deleted a chat conversation so a date wouldn’t see it',
      visited: false
    },
    {
      premise: 'Never have I ever fallen in love on the first date',
      visited: false
    },
    {
      premise: 'Never have I ever humiliated myself on a date',
      visited: false
    },
    {
      premise: 'Never have I ever gotten too drunk on the first date',
      visited: false
    },
    {
      premise: 'Never have I ever slept with a co-worker',
      visited: false
    },
    {
      premise: 'Never have I ever sent a naughty photo to someone',
      visited: false
    },
    {
      premise: 'Never have I ever had a one-night stand',
      visited: false
    },
    {
      premise: 'Never have I ever slept with my friend\'s ex',
      visited: false
    },
    {
      premise: 'Never have I ever had sx in a public place',
      visited: false
    },
    {
      premise: 'Never have I ever had a threesome',
      visited: false
    },
    {
      premise: 'Never have I ever watched porn',
      visited: false
    },
    {
      premise: 'Never have I ever used handcuffs or other implements',
      visited: false
    },
    {
      premise: 'Never have I ever faked an orgasm',
      visited: false
    },
    {
      premise: 'Never have I ever had a friend with benefits',
      visited: false
    },
    {
      premise: 'Never have I ever role played',
      visited: false
    },
    {
      premise: 'Never have I ever been walked in on whilst having sx',
      visited: false
    },
    {
      premise: 'Never have I ever tried anal',
      visited: false
    },
    {
      premise: 'Never have I ever sucked someone’s toes\n',
      visited: false
    },
    {
      premise: 'Never have I ever said the wrong name in bed\n',
      visited: false
    },
    {
      premise: 'Never have I ever shaved my body hair into a random shape for a laugh\n',
      visited: false
    },
    {
      premise: 'Never have I ever eaten dog/cat food\n',
      visited: false
    },
    {
      premise: 'Never have I ever flashed someone',
      visited: false
    },
    {
      premise: 'Never have I ever belched the alphabet',
      visited: false
    },
    {
      premise: 'Never have I ever eaten what I knew was someone else\'s food',
      visited: false
    },
    {
      premise: 'Never have I ever laughed so hard I wet myself',
      visited: false
    },
    {
      premise: 'Never have I ever picked out a wedgie in public',
      visited: false
    },
    {
      premise: 'Never have I ever rubbed someone else\'s toothbrush in something other than toothpaste',
      visited: false
    },
    {
      premise: 'Never have I ever kissed/dated/slept with someone and kept it secret',
      visited: false
    },
    {
      premise: 'Never have I ever done something so bad in public that I still cringe when I think about it',
      visited: false
    },
    {
      premise: 'Never Have I ever mooned someone',
      visited: false
    },
    {
      premise: 'Never Have I ever mooned someone',
      visited: false
    },
    {
      premise: 'Never have I ever been attracted to a cartoon character',
      visited: false
    },
    {
      premise: 'Never have I ever accidentally set something on fire',
      visited: false
    },
    {
      premise: 'Never have I ever walked in on my parents',
      visited: false
    },
    {
      premise: 'Never have I ever skinny-dipped',
      visited: false
    },
    {
      premise: 'Never have I ever shoplifted',
      visited: false
    },
    {
      premise: 'Never have I ever joined the mile-high club',
      visited: false
    },
    {
      premise: 'Never have I ever danced on a bar',
      visited: false
    },
    {
      premise: 'Never have I ever urinated in a swimming pool as an adult',
      visited: false
    },
    {
      premise: 'Never have I ever been kicked out of a pub/club/bar',
      visited: false
    },
    {
      premise: 'Never have I ever used the gents in a club because the line for the ladies was too long',
      visited: false
    },
    {
      premise: 'Never have I ever been arrested',
      visited: false
    },
    {
      premise: 'Never have I ever snuck in somewhere',
      visited: false
    },
    {
      premise: 'Never have I ever partied for more than twenty-four hours solid',
      visited: false
    },
    {
      premise: 'Never have I ever told my boss to f*** off',
      visited: false
    },
    {
      premise: 'Never have I ever called in sick and gone to the pub',
      visited: false
    },
    {
      premise: 'Never have I ever done a runner from a restaurant\n',
      visited: false
    },
    {
      premise: 'Never have I ever done more than 120mph on the motorway\n',
      visited: false
    },
    {
      premise: 'Never have I ever stolen something',
      visited: false
    },
    {
      premise: 'Never have I ever quietly farted at work and then left the room',
      visited: false
    },
    {
      premise: 'Never have I ever urinated in public',
      visited: false
    },
    {
      premise: 'Never have I ever had an "accident" in public',
      visited: false
    },
    {
      premise: 'Never have I ever blocked someone\'s toilet',
      visited: false
    },
    {
      premise: 'Never have I ever blamed a fart on a pet\n',
      visited: false
    },
    {
      premise: 'Never have I ever not changed my underwear for longer than two days',
      visited: false
    },
    {
      premise: 'Never have I ever had sx with more than one person in the same night',
      visited: false
    },
    {
      premise: 'Never have I ever sneezed into my hands and then wiped it on something',
      visited: false
    },
    {
      premise: 'Never have I ever not washed my hands after having a number two',
      visited: false
    },
    {
      premise: 'Never have I ever wet the bed over the age of 18',
      visited: false
    },
    {
      premise: 'Never have I ever done a sick burp and swallowed it',
      visited: false
    },
    {
      premise: 'Never have I ever eaten something which was on the floor for over 10 seconds\n',
      visited: false
    },
    {
      premise: 'Never have I ever bitten my toenails as an adult',
      visited: false
    },
    {
      premise: 'Never have I ever used someone else’s toothbrush',
      visited: false
    },
    {
      premise: 'Never have I ever tasted earwax',
      visited: false
    }
  ];
  mostLikelyTo = [
    {
      premise: ' Who is most likely to become a famous actor/actress?',
      visited: false
    },
    {
      premise: 'Who is most likely to sleepwalk?',
      visited: false
    },
    {
      premise: 'Who is most likely to get upset for the most stupid reasons?',
      visited: false
    },
    {
      premise: 'Who is most likely to get away with murder?',
      visited: false
    },
    {
      premise: 'Who is most likely to become a CEO at a company ranked amongst the top 100 largest in the world?',
      visited: false
    },
    {
      premise: 'Who is most likely to make it big as an actor/actress?',
      visited: false
    },
    {
      premise: 'Who is most likely to always be happy?',
      visited: false
    },
    {
      premise: 'Who is most likely to be the most creative?',
      visited: false
    },
    {
      premise: 'Who is most likely to forget important birthdays?',
      visited: false
    },
    {
      premise: ' Who is most likely to take care of the others when they are sick?\n',
      visited: false
    },
    {
      premise: 'Who is most likely to be very sensitive?',
      visited: false
    },
    {
      premise: ' Who is most likely to be the most caring?\n',
      visited: false
    },
    {
      premise: 'Who is most likely to get plastic surgery in the future?',
      visited: false
    },
    {
      premise: 'Who is most likely to worry about small things?',
      visited: false
    },
    {
      premise: 'Who is most likely to break some world record?',
      visited: false
    },
    {
      premise: 'Who is most likely to be rich?',
      visited: false
    },
    {
      premise: 'Who will most likely be unable to keep a secret for just 30 minutes?',
      visited: false
    },
    {
      premise: 'Who is most likely to remain calm during a storm?',
      visited: false
    },
    {
      premise: ' Who is most likely to forget what they told someone?',
      visited: false
    },
    {
      premise: 'Who is most likely to be a daydreamer?',
      visited: false
    },
    {
      premise: 'Who is most likely to smudge all their make-up before the start of an important event?',
      visited: false
    },
    {
      premise: 'Who is most likely to cry in a public place?',
      visited: false
    },
    {
      premise: 'Who is most likely to be a horrible boss?',
      visited: false
    },
    {
      premise: 'Who is most likely to eat their birthday cake alone?\n',
      visited: false
    },
    {
      premise: 'Who is most likely to work at Google?',
      visited: false
    },
    {
      premise: 'Who is most likely to fall out with a friend over something stupid?',
      visited: false
    },
    {
      premise: 'Who is most likely to die of something stupid?',
      visited: false
    },
    {
      premise: 'Who is most likely to be a stand-up comedian?',
      visited: false
    },
    {
      premise: 'Who is most likely to get stuck in a swing?',
      visited: false
    },
    {
      premise: 'Who is most likely to spend all their money on something stupid?',
      visited: false
    },
    {
      premise: 'Who is most likely to stay up late arguing with internet trolls?',
      visited: false
    },
    {
      premise: 'Who is most likely to wear mismatched shoes by accident?',
      visited: false
    },
    {
      premise: 'Who is most likely to be a horrible actor/actress?',
      visited: false
    },
    {
      premise: 'Who is most likely to fall asleep on the bus and pass their station?',
      visited: false
    },
    {
      premise: 'Who is most likely to eat cat food?',
      visited: false
    },
    {
      premise: ' Who is most likely to become a gossip columnist?',
      visited: false
    },
    {
      premise: 'Who is most likely to go to jail for doing something stupid?\n',
      visited: false
    },
    {
      premise: 'Who is most likely to embarrass themselves in front of their secret crush / known crush?',
      visited: false
    },
    {
      premise: 'Who is most likely to develop a habit of saying “umm” and “ahh”?',
      visited: false
    },
    {
      premise: 'Who is most likely to lock their key in the car?',
      visited: false
    },
    {
      premise: 'Who is most likely to have a crush on an anime character?',
      visited: false
    },
    {
      premise: 'Who is most likely to eat dog food?',
      visited: false
    },
    {
      premise: 'Who is most likely to watch porn as soon as they wake up?',
      visited: false
    },
    {
      premise: 'Who is most likely to eat something gross on a dare?',
      visited: false
    },
    {
      premise: 'Who is most likely to be grumpy after a one-night stand?',
      visited: false
    },
    {
      premise: 'Who is most likely to kill a cheating partner?',
      visited: false
    },
    {
      premise: 'Who is most likely to be the first one to die in a zombie apocalypse?',
      visited: false
    },
    {
      premise: 'Who is most likely to laugh at the wrong moment?',
      visited: false
    },
    {
      premise: 'Who is most likely to sxt in class?',
      visited: false
    },
    {
      premise: 'Who is most likely to kiss & tell?',
      visited: false
    },
    {
      premise: 'Who is most likely to be so awkward around in-laws?',
      visited: false
    },
    {
      premise: 'Who is most likely to get married first?',
      visited: false
    },
    {
      premise: 'Who is most likely to have the most children?',
      visited: false
    },
    {
      premise: 'Who is most likely to be tired of their partner after two weeks?',
      visited: false
    },
    {
      premise: 'Who is most likely to fall asleep on a date?',
      visited: false
    },
    {
      premise: 'Who is most likely to fall in love with a werewolf?',
      visited: false
    },
    {
      premise: 'Who is most likely not to take a shower for a week?',
      visited: false
    },
    {
      premise: 'Who is most likely to have their nudes leaked?',
      visited: false
    },
    {
      premise: 'Who is most likely to accidentally set the house on fire?\n',
      visited: false
    },
    {
      premise: ' Who is most likely to date two guys / girls at a time?',
      visited: false
    },
    {
      premise: 'Who is most likely to cry because of a sad movie?',
      visited: false
    },
    {
      premise: 'Who is most likely to try a threesome?',
      visited: false
    },
    {
      premise: ' Who is most likely to get a heart attack from watching a horror movie?',
      visited: false
    },
    {
      premise: 'Most likely to perform a sxual act while drunk?',
      visited: false
    },
    {
      premise: 'Most likely to have sx with another person in the room?',
      visited: false
    },
    {
      premise: 'Most likely to make a move on a teacher?',
      visited: false
    },
    {
      premise: 'Most likely to talk dirty on the telephone?',
      visited: false
    },
    {
      premise: 'Most likely to text their ex when drunk?',
      visited: false
    },
  ];
}
