import {Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewRef} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PopupComponent} from '../popup/popup.component';
import {MatDialog} from '@angular/material/dialog';
import { backendAddress } from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.scss']
})
export class MyOffersComponent implements OnInit {

  constructor(private api: ConfigService, private snackBar: MatSnackBar, public dialog: MatDialog, private translate: TranslateService) { }

  @Input() loggedInUser: any;

  isLoaderVisible = false;
  offers = [
    {
      id: 1,
      user_id: null,
      art_object_id: 14,
      email: '',
      name: '',
      phone_number: '',
      offer: '',
      details: '',
      status: 0,
      created_at: '',
      updated_at: '',
      art_object: {
        id: 0,
        title: '',
        description: '',
        picture_category_id: 0,
        availability: 0,
        dimension: '',
        technique: '',
        year: 0,
        file_name: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
        file_path: '',
      }
    }
  ];
  offersPag = [
    {
      id: 1,
      user_id: null,
      art_object_id: 14,
      email: '',
      name: '',
      phone_number: '',
      offer: '',
      details: '',
      status: 0,
      created_at: '',
      updated_at: '',
      art_object: {
        id: 0,
        title: '',
        description: '',
        picture_category_id: 0,
        availability: 0,
        dimension: '',
        technique: '',
        year: 0,
        file_name: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
        file_path: '',
      }
    }
  ];
  states = [
    {
      id: 1,
      name: 'statePending',
    },
    {
      id: 2,
      name: 'stateApproved',
    }
  ];
  areFiltersVisible = true;
  currentPage = 0;
  maxPages = 0;
  offerPerPage = 12;
  firstPaintingIndex = 0;
  backendAddress = backendAddress.value;
  resetPagination(): void{
    this.currentPage = 0;
    this.maxPages = 0;
    this.offerPerPage = 5;
    this.firstPaintingIndex = 0;
    this.initiatePages();
  }
  initiatePages(): void {
    this.offersPag = this.offers;
    this.maxPages = Math.round(this.offersPag.length / this.offerPerPage + 0.5);
    this.firstPaintingIndex = this.offersPag[0].id;
    const newOffersPerPage = [
        {
          id: 1,
          user_id: null,
          art_object_id: 14,
          email: '',
          name: '',
          phone_number: '',
          offer: '',
          details: '',
          status: 0,
          created_at: '',
          updated_at: '',
          art_object: {
            id: 0,
            title: '',
            description: '',
            picture_category_id: 0,
            availability: 0,
            dimension: '',
            technique: '',
            year: 0,
            file_name: '',
            user_id: 0,
            created_at: '',
            updated_at: '',
            file_path: '',
          }
        }
      ];
    let ct = 0;
    this.offersPag.forEach(off => {
      if (ct < this.offerPerPage){
        // @ts-ignore
        newOffersPerPage.push(off);
        ct++;
      }
    });
    this.offersPag = newOffersPerPage;
    this.offersPag.shift();
  }
  nextPage(): void {
    if (this.currentPage < this.maxPages - 1 ) {
      this.offersPag = this.offers;
      this.currentPage++;
      this.reindexPaintings();
    }
  }

  previousPage(): void {
    if (this.currentPage > 0){
      this.currentPage--;
      this.offersPag = this.offers;
      this.reindexPaintings();
    }
  }
  reindexPaintings(): void {
    // this.is_painting_selected = false;
    const newOffersPerPage = [
      {
        id: 1,
        user_id: null,
        art_object_id: 14,
        email: '',
        name: '',
        phone_number: '',
        offer: '',
        details: '',
        status: 0,
        created_at: '',
        updated_at: '',
        art_object: {
          id: 0,
          title: '',
          description: '',
          picture_category_id: 0,
          availability: 0,
          dimension: '',
          technique: '',
          year: 0,
          file_name: '',
          user_id: 0,
          created_at: '',
          updated_at: '',
          file_path: '',
        }
      }
    ];
    let ct = this.offerPerPage;

    for (let i = this.currentPage * this.offerPerPage; i <= this.offersPag.length - 1; i++){
      if (ct > 0){
        newOffersPerPage.push(this.offersPag[i]);
        ct--;
      }
    }
    this.offersPag = newOffersPerPage;
    this.offersPag.shift();
    window.scroll(0, 0);
  }


  modifyDataUpdated(): void {
    this.offers.forEach(offer => {
      // @ts-ignore
      const offerSplit = offer.updated_at.split('T');
      // @ts-ignore
      offer.updated_at = offerSplit[0];
    });
  }
  seeBuyerDetails(offer: any): void {
    let message = '';
    this.translate.get('offerMadeBy').subscribe(madeBy => {
      this.translate.get('withEmail').subscribe(withEmail => {
        this.translate.get('andPhoneNumber').subscribe(andPhoneNr => {
          this.translate.get('otherDetails').subscribe(otherDetails => {
            message = madeBy + offer.name + '\'' + withEmail + offer.email + '\'' +
              andPhoneNr + offer.phone_number + '\'' + otherDetails + offer.details + '\'';
          });
        });
      });
    });
    this.dialog.open(PopupComponent, {
      data: {popupText: message, popupButtonsNotVsible: 'da'},
      disableClose: true
    });
  }
  getOffers(): void {
    this.isLoaderVisible = true;
    this.api.getMyOffers().subscribe(data => {
        // @ts-ignore
        this.offers = data.reverse();
        if (this.offers.length > 0){
          this.resetPagination();
          this.modifyDataUpdated();
        }
        this.isLoaderVisible = false;
      },
      error => {
        console.log('getLoggedInUserArtObjects error', error);
        this.isLoaderVisible = false;
      });
  }
  sendEmails(offer: any, state: any): void {
    if (state === '1') {
      state = 'pending';
    } else {
      state = 'approved';
    }
    const message = 'Artistul ' + this.loggedInUser.full_name + ' a schimbat starea ofertei cu numarul ' + offer.id +
      ' primite de la userul ' + offer.name + ' in \'' + state + '\'';

    this.api.SendMultipleEmails(this.loggedInUser.email, offer.email, this.loggedInUser.full_name, message, 'aa', 'bb').subscribe(data => {
      console.log('sendimg multiple emails', data);
    }, err => {
      this.translate.get('noConfirmationEmail').subscribe(msg => {
        this.snackBar.open(msg, '',
          {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        this.isLoaderVisible = false;
      });
    });
  }
  changeOfferState(selectedOffer: any, event: Event): void {
    // @ts-ignore
    this.isLoaderVisible = true;
    // @ts-ignore
    document.getElementById('selectid-' + selectedOffer.id).className = 'transparent-select state-' + event.target.value;
    // @ts-ignore
    this.api.postOfferEdit(selectedOffer.id, {status: event.target.value}).subscribe(data => {
        // @ts-ignore
        this.sendEmails(selectedOffer, event.target.value);
        this.isLoaderVisible = false;
        this.translate.get('modificationSuccessfullyMade').subscribe(message => {
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'success-snack-bar',
              horizontalPosition: 'end', verticalPosition: 'top'});
        });
      },
      error => {
        console.log('postOfferEdit error', error);
        this.translate.get('errorOccurred').subscribe(message => {
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
          this.isLoaderVisible = false;
        });
      });
  }
  saveStates(): void {
  }
  ngOnInit(): void {
    this.getOffers();
  }

}
