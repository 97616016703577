<div class="login-container" id="login-container">
  <app-loader *ngIf="isLoaderVisible"></app-loader>
  <div class="form">

    <p (click)="back()" class="svg" style="margin-left: 2%" *ngIf="!isKnowMoreVisible && isBackVisible"><img src="assets/other/svg/arrow.svg" alt="arrow icon" class="svg"/></p>

    <p (click)="isKnowMoreVisible = false" *ngIf="isKnowMoreVisible && isBackVisible" style=" margin-left: 2%" ><img src="assets/other/svg/arrow.svg" alt="arrow icon" class="svg"/></p>

<!--    <img src="assets/logo/simple_lupusor_white.svg"/>-->
    <div  class="form-container">
      <p class="succefull-register" *ngIf="registerSuccessfullMessage">{{'registerWasSuccesfull' | translate}}</p>

      <div class="messages">
        <p *ngIf="message.length > 0" class="message">{{message}}</p>
        <p *ngIf="errorMessage.length > 0" class="error-message">{{errorMessage}}</p>
      </div>

      <div *ngIf="isLogin && !isKnowMoreVisible">
        <form #form="ngForm">
          <input type="email" placeholder="{{'email' | translate}}" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
          <input type="password" placeholder="{{'password' | translate}}" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
<!--          <button mat-raised-button (click)="submitLogin()" (keydown.enter)="submitLogin()">{{'logIn' | translate}}</button>-->
          <button mat-raised-button (click)="send(form)" (keydown.enter)="send(form)">{{'logIn' | translate}}</button>
        </form>

        <span class="register">{{'noAccount?' | translate}} <a (click)="register()">{{'registerHere' | translate}}</a></span>

        <span class="register"><a (click)="continueNoAccount()">{{'continueWithoutAccount' | translate}}</a></span>

        <span class="register"><a (click)="knowMore()">{{'knowMore' | translate}}</a></span>
      </div>

      <div *ngIf="isRegisterVisible">
        <app-register></app-register>
      </div>

      <div *ngIf="isForgotPass && !isKnowMoreVisible">
<!--        <p (click)="backLogin()" class="back_login"><img src="assets/other/svg/arrow.svg" class="svg"/></p>-->
<!--        <p>Vom trimite pe mail actualizarea parolei</p>-->
<!--        <input type="email" placeholder="Email*" name="email" [(ngModel)]="email">-->
<!--        <button (click)="submitForgotPass()">Trimite</button>-->
      </div>

      <div *ngIf="isKnowMoreVisible">
        <div class="buttons">
          <button [className]="isKnowArtist ? 'mat-simple-snackbar-active' : 'mat-simple-snackbar'" (click)="isKnowArtist = true">{{'userRoleArtist' | translate}}</button>
          <button [className]="!isKnowArtist ? 'mat-simple-snackbar-active' : 'mat-simple-snackbar'"(click)="isKnowArtist = false">{{'userRoleBuyer' | translate}}</button>
        </div>
        <div class="know-more" *ngIf="!isKnowArtist">
          <h1>{{'WYMKBh1' | translate}}</h1>
          <p>
            {{'whatYMKBp1' | translate}}
          </p>
          <h2>{{'WYMKBh2' | translate}}</h2>
          <p>
            {{'whatYMKBp2' | translate}}
          </p>
          <h2>{{'WYMKBh3' | translate}}</h2>
          <p>
            {{'whatYMKBp3' | translate}}
          </p>
          <h2>{{'WYMKBh3' | translate}}</h2>
          <p> {{'whatYMKBp4' | translate}}
          </p>
        </div>

        <div class="know-more" *ngIf="isKnowArtist">
          <h1>
            {{'WYMKAh1' | translate}}
          </h1>
          <h2>
            {{'WYMKAh2' | translate}}
          </h2>
          <p>
            {{'WYMKAp1' | translate}}
            <br>
            {{'WYMKAp2' | translate}}
           </p>
          <h2>
            {{'WYMKAh3' | translate}}
          </h2>
          <p>
            {{'WYMKAp3' | translate}}
          </p>
          <h2>
            {{'WYMKAh4' | translate}}
          </h2>
          <p>
            {{'WYMKAp4' | translate}}
          </p>
        </div>

      </div>
    </div>
  </div>
</div>
