<mat-dialog-content class="mat-typography">
  <h3>{{data.popupText}}</h3>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="data.popupButtonsNotVsible !== 'da'">
  <button mat-button [mat-dialog-close]="false" >Nu</button>
  <button mat-button [mat-dialog-close]="true" >Da</button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="data.popupButtonsNotVsible === 'da'">
  <button mat-button [mat-dialog-close]="false" >Ok</button>
</mat-dialog-actions>
