import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PaintingCathegoryService {
  // tslint:disable-next-line:variable-name
  private paintingCathegory_Subject = new BehaviorSubject(0);
  // tslint:disable-next-line:variable-name
  current_paintingCathegory = this.paintingCathegory_Subject.asObservable();

  constructor(){}

  // tslint:disable-next-line:typedef variable-name
  update_paintingCathegory(new_paintingCathegory: number){
    this.paintingCathegory_Subject.next(new_paintingCathegory);
  }
}
