import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from '../config/config.service';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private router: Router, private api: ConfigService, private translate: TranslateService,
              private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) { }
  message = '';
  errorMessage = '';
  email = '';
  password = '';
  confirmPassword = '';
  firstName = '';
  lastName = '';
  phone = '';
  termsConditions = false;
  agreeData = false;
  justMail = false;
  phoneAndMail = false;
  roleId = 0;
  roleIds = [
    {
      id: 0,
      name: 'Alege tip cont',
    },
    {
      id: 1,
      name: 'buyer',
    },
    {
      id: 3,
      name: 'wannabeartist',
    },
  ];


  resetFields(): void {
    this.email = '';
    this.password = '';
    this.confirmPassword = '';
  }

  announceMeWannaBeArtist(): void {
    // send email to me
    this.api.SendEmail(this.firstName + ' ' + this.lastName, this.email, 'vrea sa fie artist').subscribe(
      data => {
        console.log('data', data);
      },
      error => {
        this.translate.get('errorOccurred').subscribe(msg => {
          this.errorMessage = msg;
        });
      });
  }

  submitRegister(): void {
    if (this.checkEmail()){
      if (this.checkPasswordsMatch()){
        if (this.checkAllFieldsCompleted()) {
          this.api.Register(this.firstName, this.lastName, this.password, this.email, this.phone,
            Number(this.agreeData), Number(this.justMail), Number(this.phoneAndMail), this.roleId).subscribe(async (data: any) => {
              this.errorMessage = '';
              if (this.roleId === 3) {
                await this.announceMeWannaBeArtist();
              }
              const route = this.router.url;
              localStorage.setItem('registerSuccesfull', 'true');
              if (route.includes('Register')) {
                this.backToLogin();
              } else {
                window.location.reload();
              }
            },
            err => {
              this.translate.get('errorOccurred').subscribe(message => {
                this.errorMessage = message;
              });
            }
          );
        } else {
          this.translate.get('completeRequiredFields').subscribe(message => {
            this.errorMessage = message;
          });
        }
      }
    }
  }

  checkEmail(): boolean {
    // return true;
    if (this.email.indexOf('@') !== -1 && this.email.indexOf('.') !== -1 && this.email.length > 2) {
      return true;
    } else {
      this.translate.get('invalidEmail').subscribe(message => {
        this.errorMessage = message;
      });
      return false;
    }
  }
  // tslint:disable-next-line:typedef
  checkAllFieldsCompleted() {
    if (this.lastName !== '' && this.firstName !== '' && this.password !== '' && this.agreeData &&
      this.phone !== '' && this.termsConditions && this.roleId !== 0) {
      return true;
    } else {
      return false;
    }
  }
  checkPasswordsMatch(): boolean {
    if (this.password === this.confirmPassword){
      if (this.password.length < 10){
        this.translate.get('passwordMin10').subscribe(message => {
          this.errorMessage = message;
        });
        return false;
      } else {
        return true;
      }
    } else {
      this.translate.get('passwordsMustBeIdentical').subscribe(message => {
        this.errorMessage = message;
      });
      return false;
    }
  }
  send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submitContactForm')
      .subscribe((token: string) => {
          // console.log(`Token [${token}] generated`);
          this.submitRegister();
        },
        error => {
          console.log('error', error);
          this.snackBar.open('Recaptcha is not working properly', '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        });
  }

  backToLogin(): void {
    this.router.navigate(['Login']);
  }

  ngOnInit(): void {
  }
}
