import {Component, OnInit} from '@angular/core';
import {GamesService} from '../shared/games';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(private _gameJson: GamesService) {
  }

  isGameChosen = false;
  gamePremises: any;
  activePremise = '';

  ChooseGame(gameNr: number): void {
    switch (gameNr) {
      case 1: {
        this.gamePremises = this._gameJson.neverHaveIEver;
        this.isGameChosen = true;
        this.nextPremise();
        break;
      }
      case 2: {
        this.gamePremises = this._gameJson.mostLikelyTo;
        this.isGameChosen = true;
        this.nextPremise();
        break;
      }
    }
  }

  getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }

  nextPremise(): void {
    if (this.gamePremises.length > 0) {
      const randomNr = this.getRandomInt(this.gamePremises.length);
      this.activePremise = this.gamePremises[randomNr].premise;
      this.gamePremises.splice(this.gamePremises.indexOf(this.gamePremises[randomNr]), 1);
    }
  }

  ngOnInit(): void {
  }

}
