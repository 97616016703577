<div class="events-container">
  <!--  <div id='stars'></div>-->
  <!--  <div id='stars2'></div>-->
  <!--  <div id='stars3'></div>-->
  <!--  <div id='stars4'></div>-->
  <div *ngIf="isEventSelected == false; else eventsSelectedTrue">
    <div *ngIf="events_planned">
      <h1 *ngIf="days_left === 0; else notToday">{{'todayIsTheSpecialDay' | translate}}</h1>
      <ng-template #notToday>
        <h1 *ngIf="days_left === 1; else manyDays">{{'tomorrowIsTheSpecialDay' | translate}}</h1>
        <ng-template #manyDays>
<!--          <h1 class="fancy">{{days_left}} {{'daysToGoUntill' | translate}}</h1>-->
          <div class="remaining_days" *ngIf="isDateReady">
            <div class="red"><span>{{amrDays}}</span>  <p>zile</p></div>
            <div class="h2"><span>{{amrHours}}</span>  <p>ore</p></div>
            <div class="h2"><span>{{amrMinutes}}</span>  <p>min</p></div>
            <div class="h2"><span class="blob">{{amrSeconds}}</span>  <p>sec</p></div>
          </div>
        </ng-template>
      </ng-template>
      <div class="events-calendar">
        <table>
          <tr>
            <td class="calendar-text" *ngIf="!events_planned">
              <p style="text-align: right">{{'noEventsScheduled' | translate}}<br>
                {{'keepInTouch' | translate}}<br>
              </p>
            </td>
            <td *ngIf="events_planned" style="width:50%">
              <div style="justify-content: center; text-align: center">
                <img src="assets/exhibition5/event-cover.png" alt="exhibition image" class="poster">
              </div>
              <div *ngIf="days_left === 0" class="padding">
              </div>
            </td>
            <td class="calendar" style="width: 50%; padding: 0 7%">
              <div class="month">
                <ul>
                  <li>
                    {{ month | translate}}<br>
                    <span style="font-size:18px">{{this.year}}</span>
                  </li>
                </ul>
              </div>
              <ul class="weekdays">
                <li *ngFor="let day of days">
                  {{ day | translate}}
                </li>
              </ul>
              <ul class="days">
                <li *ngFor="let day of list_of_days">
                  <div *ngIf="day <= previous_months_days then lastMonth else thisMonth"></div>
                  <ng-template #lastMonth>
                    <div class="last-month"></div>
                  </ng-template>
                  <ng-template #thisMonth>
                    <div
                      *ngIf="(day - previous_months_days === today) && (today !== upcommingEvent.day); then activeDay else normalDay"></div>
                    <ng-template #activeDay>
                      <div class="active">
                        {{day - previous_months_days}}
                      </div>
                    </ng-template>
                    <ng-template #normalDay>
                      <div *ngIf="day - previous_months_days == upcommingEvent.day">
                        <div class="special" *ngIf="events_planned">
                          {{day - previous_months_days}}
                        </div>
                        <div *ngIf="!events_planned">
                          {{day - previous_months_days}}
                        </div>
                      </div>
                      <div *ngIf="day - previous_months_days != upcommingEvent.day">
                        {{day - previous_months_days}}
                      </div>
                    </ng-template>
                  </ng-template>
                </li>
              </ul>
              <br>
              <div class="green-square">{{ 'specialDay' | translate }}</div>
              <div class="blue-square">{{ 'currentDay' | translate }}</div>
              <!--        <div class="simple-square">{{ 'normalDay' | translate }}</div>-->
            </td>
          </tr>
          <tr>
            <td style="width: 50%" *ngIf="events_planned">
              <h3>{{'event5Details1' | translate}}</h3>
              <p>{{'event5Details2' | translate}}</p>
              <p>{{'event5Details3' | translate}}</p>

              <h3>{{'event5Details4' | translate}}</h3>
              <p>{{'event5Details5' | translate}}</p>

              <h3>{{'events5Details6' | translate}}</h3>
              <p>{{'events5Details7' | translate}}</p>
              <p>{{'events5Details8' | translate}}</p>
              <p>{{'events5Details9' | translate}}</p>
              <p>{{'events5Details10' | translate}}</p>
              <p>{{'events5Details11' | translate}}</p>
            </td>
            <td style=" width: 50%" *ngIf="events_planned">
              <div class="test">
                <h3>{{ 'where' | translate }}</h3>
                <a [href]="upcommingEvent.locationHref" target="_blank">
                  <p>
                    {{upcommingEvent.location}}
                  </p>
                </a>
                <br>
                <h3>{{ 'when' | translate }}</h3>
                <p>
                  0{{upcommingEvent.day}}.0{{upcommingEvent.month}}.{{upcommingEvent.year}} <br>
                  (13:00 - 18:00)
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="responsive-events-calendar" id="width">
        <table>
          <tr>
            <td class="calendar">
              <div class="month">
                <ul>
                  <li>
                    {{ month | translate}}<br>
                    <span style="font-size:18px">{{this.year}}</span>
                  </li>
                </ul>
              </div>
              <ul class="weekdays">
                <li *ngFor="let day of days">
                  {{ day | translate}}
                </li>
              </ul>
              <ul class="days">
                <li *ngFor="let day of list_of_days">
                  <div *ngIf="day <= previous_months_days then lastMonth else thisMonth"></div>
                  <ng-template #lastMonth>
                    <div class="last-month"></div>
                  </ng-template>
                  <ng-template #thisMonth>
                    <div
                      *ngIf="(day - previous_months_days === today) && (today !== upcommingEvent.day); then activeDay else normalDay"></div>
                    <ng-template #activeDay>
                      <div class="active">
                        {{day - previous_months_days}}
                      </div>
                    </ng-template>
                    <ng-template #normalDay>
                      <div *ngIf="day - previous_months_days == upcommingEvent.day">
                        <div class="special" *ngIf="events_planned">
                          {{day - previous_months_days}}
                        </div>
                        <div *ngIf="!events_planned">
                          {{day - previous_months_days}}
                        </div>
                      </div>
                      <div *ngIf="day - previous_months_days != upcommingEvent.day">
                        {{day - previous_months_days}}
                      </div>
                    </ng-template>
                  </ng-template>
                </li>
              </ul>
              <br>
              <div class="green-square">{{ 'specialDay' | translate }}</div>
              <div class="blue-square">{{ 'currentDay' | translate }}</div>
              <!--        <div class="simple-square">{{ 'normalDay' | translate }}</div>-->
            </td>
          </tr>
          <tr>
            <td class="calendar-text" *ngIf="!events_planned">
              <p style="text-align: right">{{'noEventsScheduled' | translate}}<br>
                {{'keepInTouch' | translate}}<br>
              </p>
            </td>
            <td *ngIf="events_planned">
              <h1 *ngIf="days_left === 0; else notToday">{{'todayIsTheSpecialDay' | translate}}</h1>
              <ng-template #notToday>
                <h1 *ngIf="days_left === 1; else manyDays">{{ 'tomorrowIsTheSpecialDay' | translate }}
                  <br> {{ 'seeYouThere' | translate }}</h1>
                <ng-template #manyDays>
                  <!--            <h1>{{days_left}} {{'daysToGoUntill' | translate}}</h1>-->
                </ng-template>
              </ng-template>
              <div style="justify-content: center; text-align: center">
                <img src="assets/exhibition5/event-cover-short.jpg" alt="exhibition image" class="poster">
              </div>
              <div style="text-align: center">
                <h3>{{'event5Details1' | translate}}</h3>
                <p>{{'event5Details2' | translate}}</p>
                <p>{{'event5Details3' | translate}}</p>

                <h3>{{'event5Details4' | translate}}</h3>
                <p>{{'event5Details5' | translate}}</p>

                <h3>{{'events5Details6' | translate}}</h3>
                <p>{{'events5Details7' | translate}}</p>
                <p>{{'events5Details8' | translate}}</p>
                <p>{{'events5Details9' | translate}}</p>
                <p>{{'events5Details10' | translate}}</p>
                <p>{{'events5Details11' | translate}}</p>
              </div>
              <table class="in-table" *ngIf="events_planned">
                <tr>
                  <td style="width: 50%"><h3>{{'where' | translate}}</h3></td>
                  <td style="width: 50%"><h3>{{'when' | translate}}</h3></td>
                </tr>
                <tr>
                  <td style="width: 50%">
                    <a [href]="upcommingEvent.locationHref" target="_blank">
                      <p style="justify-content: center; text-align: center">
                        {{upcommingEvent.location}}
                      </p>
                    </a>
                  </td>
                  <td style="width: 50%">
                    <p style="justify-content: center; text-align: center">
                      {{upcommingEvent.day}}.0{{upcommingEvent.month}}.{{upcommingEvent.year}} <br>
                      (13:00 - 18:00)
                    </p>
                  </td>
                </tr>
              </table>
              <br>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="all-events-container">
      <div class="single-event-container">
        <img src="/assets/exhibition5/event-cover.png" alt="exhibition image">
        <button mat-raised-button class="simple-button" (click)="showEvent(5)">{{'seeDetails' | translate}}</button>
      </div>

      <div class="single-event-container">
        <img src="/assets/exhibition4/final-site.png" alt="exhibition image">
        <button mat-raised-button class="simple-button" (click)="showEvent(4)">{{'seeDetails' | translate}}</button>
      </div>

      <div class="single-event-container">
        <img src="/assets/other/ev-expo3.png" alt="exhibition image">
        <button mat-raised-button class="simple-button" (click)="showEvent(3)">{{'seeDetails' | translate}}</button>
      </div>

      <div class="single-event-container">
        <img src="assets/other/ev-expo2.png" alt="exhibition image">
        <button mat-raised-button class="simple-button" (click)="showEvent(2)">{{'seeDetails' | translate}}</button>
      </div>

      <div class="single-event-container">
        <img src="assets/other/ev-expo1.png" alt="exhibition image">
        <button mat-raised-button class="simple-button" (click)="showEvent(1)">{{'seeDetails' | translate}}</button>
      </div>
    </div>
  </div>
  <ng-template #eventsSelectedTrue>
    <button (click)="isEventSelected = false" class="back">
      <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(90deg);">
      {{'backToGalley' | translate}}
    </button>
    <app-event-details-page [event]="eventSelected"></app-event-details-page>
  </ng-template>


  <p class="responsive-break"></p>
</div>

