<div class="painting-page-container" id="painting-page-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="painting-popup" *ngIf="isPaintingPopupVisible">
    <button mat-button (click)="togglePopup()">X Inchide</button>
    <img src="{{selectedPainting.path}}" alt="{{selectedPainting.name | translate}}">
  </div>

  <button (click)="toGalerry()" style=" margin-left: 2%">
    <img src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(90deg)">
    {{'backToGalley' | translate}}
  </button>
  <div class="hidden-area">
    <table>
      <tr class="background-img" id="room-for-painting">
        <td class="room-img" >
          <div class="moving-boundaries">
            <div id="picture-frame" class="picture-frame">
              <img id="framed-painting" src="{{selectedPainting.path}}" alt="{{selectedPainting.name | translate}}" class="in-room-painting-{{selected_frame}}">
            </div>
          </div>
          <br>
        </td>
        <td class="painting-detail">
          <h1>{{selectedPainting.name | translate}}</h1>
          <p class="description">{{selectedPainting.description | translate}}</p>

          <p class="dimension">
            Artist:
            <br>
            {{artistName}}
            <br>
            {{artistEmail}}
            <br>
            {{artistPhone}}
          </p>

          <p class="dimension">
            {{selectedPainting.dimension}},
            {{selectedPainting.technique | translate}}
          </p>

          <p class="dimension price" *ngIf="selectedPainting.price === 'setMare'">
            150 lei
          </p>
          <p class="dimension price" *ngIf="selectedPainting.price === 'setMic'">
            125 lei
          </p>

          <div *ngIf="selectedPainting.availability == 0" class="flex">
            <div class="available"></div>{{'available' | translate}}
          </div>
          <div *ngIf="selectedPainting.availability == 1" class="flex">
            <div class="not-available"></div>{{'onOrder' | translate}}
          </div>

          <p class="offer" *ngIf="selectedPainting.reservations_count && selectedPainting.reservations_count>1">
            ( {{'reservedBy' | translate}} {{selectedPainting.reservations_count}} {{'people' | translate}} )
          </p>
          <p class="offer" *ngIf="selectedPainting.reservations_count && selectedPainting.reservations_count == 1">
            ( {{'reservedBy' | translate}} {{selectedPainting.reservations_count}} {{'person' | translate}} )
          </p>

          <button mat-raised-button class="offer" *ngIf="!isUserArtist && selectedPainting.availability == 0" (click)="placeOffer()">
            {{'reserve' | translate}}
          </button>
          <button mat-raised-button class="offer" *ngIf="!isUserArtist && selectedPainting.availability == 1" (click)="placeOffer()">
            {{'preOrder' | translate}}
          </button>


          <p class="bold">{{'galleryStep1' | translate}}</p>
          <div id="rooms">
            <img (click)="ChangRoom(1)" src="assets/room/room1.jpg" alt="imagine camera" class="button-img" id="room1">
            <img (click)="ChangRoom(3)" src="assets/room/room3.jpg" alt="imagine camera" class="button-img" id="room3">
            <img (click)="ChangRoom(2)" src="assets/room/room2.jpg" alt="imagine camera" class="button-img" id="room2">
            <img (click)="ChangRoom(4)" src="assets/room/room4.jpg" alt="imagine camera" class="button-img" id="room4">
            <!--            <img (click)="ChangRoom(5)" src="assets/room/room5.png" class="button-img" id="room5">-->
<!--            <img (click)="ChangRoom(6)" src="assets/room/room6.png" class="button-img" id="room6">-->
            <img (click)="ShowPainting()" src="assets/other/svg/image-correction.png" alt="imagine pictura full size" class="button-img">
          </div>
          <p></p>

          <p class="bold">{{'galleryStep2' | translate}}</p>
          <div id="frames">
            <img (click)="ChangFrame('1')" src="assets/frames/frame1.png" alt="imagine rama" class="button-img" id="frame1">
            <img (click)="ChangFrame('2')" src="assets/frames/frame2.png" alt="imagine rama" class="button-img" id="frame2">
            <img (click)="ChangFrame('3')" src="assets/frames/frame3.png" alt="imagine rama" class="button-img" id="frame3">
          </div>

        </td>
      </tr>
    </table>
  </div>

  <div class="responsive_hidden-area">
    <table>
      <tr>
        <td class="room-img responsive_background-img" id="responsive_room-for-painting" >
          <div class="">
            <div class="moving-boundaries">
              <div id="responsive_picture-frame" class="picture-frame">
                <img id="responsive_framed-painting" src="{{selectedPainting.path}}" alt="{{selectedPainting.name | translate}}"
                     class="in-room-painting-{{selected_frame}}">
              </div>
            </div>

          </div>
          <div class="test">
            <h1>{{selectedPainting.name | translate}}</h1>
            <p class="description">{{selectedPainting.description | translate}}</p>

            <p class="dimension">
              Artist:
              <br>
              {{artistName}}
              <br>
              {{artistEmail}}
              <br>
              {{artistPhone}}
            </p>

            <p class="dimension" style="margin-top: 5%">
              {{selectedPainting.dimension}},
              {{selectedPainting.technique | translate}}
            </p>

            <p class="dimension price" *ngIf="selectedPainting.price === 'setMare'">
              150 lei
            </p>
            <p class="dimension price" *ngIf="selectedPainting.price === 'setMic'">
              125 lei
            </p>

            <div *ngIf="selectedPainting.availability == 0" class="flex">
              <div class="available"></div>{{'available' | translate}}
            </div>
            <div *ngIf="selectedPainting.availability == 1" class="flex">
              <div class="not-available"></div>{{'onOrder' | translate}}
            </div>

            <p class="offer" *ngIf="selectedPainting.reservations_count && selectedPainting.reservations_count>1">
              ( rezervat de {{selectedPainting.reservations_count}} persoane )
            </p>
            <p class="offer" *ngIf="selectedPainting.reservations_count && selectedPainting.reservations_count == 1">
              ( rezervat de {{selectedPainting.reservations_count}} persoana )
            </p>

            <button mat-raised-button class="offer" *ngIf="!isUserArtist && selectedPainting.availability == 0" (click)="placeOffer()">
              {{'reserve' | translate}}
            </button>
            <button mat-raised-button class="offer" *ngIf="!isUserArtist && selectedPainting.availability == 1" (click)="placeOffer()">
              {{'preOrder' | translate}}
            </button>

            <div class="choose">
              <p>{{'galleryStep1' | translate}}</p>
              <img (click)="ChangRoom(1)" src="assets/room/room1.jpg" alt="imagine camera pentru pictura" class="button-img" id="resp-room1">
              <img (click)="ChangRoom(3)" src="assets/room/room3.jpg" alt="imagine camera pentru pictura" class="button-img" id="resp-room3">
              <img (click)="ChangRoom(2)" src="assets/room/room2.jpg" alt="imagine camera pentru pictura" class="button-img" id="resp-room2">
              <img (click)="ChangRoom(4)" src="assets/room/room4.jpg" alt="imagine camera pentru pictura" class="button-img" id="resp-room4">
              <!--            <img (click)="ChangRoom(5)" src="assets/room/room5.png" class="button-img" id="resp-room5">-->
              <img (click)="ShowPainting()" src="assets/other/svg/image-correction.png" class="button-img">

              <p>{{'galleryStep2' | translate}}</p>
              <img (click)="ChangFrame('1')" src="assets/frames/frame1.png" alt="imagine rama pentru pictura" class="button-img" id="resp-frame1">
              <img (click)="ChangFrame('2')" src="assets/frames/frame2.png" alt="imagine rama pentru pictura" class="button-img" id="resp-frame2">
              <img (click)="ChangFrame('3')" src="assets/frames/frame3.png" alt="imagine rama pentru pictura" class="button-img" id="resp-frame3">
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
