<div class="subscription-container">
  <div *ngIf="subscriptionAvailable; else notAvailable">
    <h1>{{'actualSubscription' | translate}}</h1>

    <mat-card>
      <mat-card-header>
        <mat-card-title>{{userSubscription.name}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          {{userSubscription.description}}
        </p>
        <h1 *ngIf="userSubscription.price !== 0">{{userSubscription.price}} RON/ luna</h1>
        <h1 *ngIf="userSubscription.price == 0" class="free">Gratis</h1>
      </mat-card-content>
    </mat-card>

    <!--  <div class="subscriptions">-->
    <!--    <p *ngFor="let subscription of subscriptions">-->
    <!--      {{subscription.name}} // {{subscription.price}}-->
    <!--    </p>-->
    <!--  </div>-->

    <h1 class="margin-top">{{'availableSubscriptions' | translate}}</h1>

    <div class="field-container">
      <div *ngFor="let subscription of subscriptions" class="field-{{(subscription.id !== userSubscription.id)}}">
        <mat-card *ngIf="subscription.id !== userSubscription.id">
          <mat-card-header>
            <mat-card-title>{{subscription.name}}</mat-card-title>
          </mat-card-header>
          <img mat-card-image src="{{subscription.imgUrl}}" alt="Photo of a Shiba Inu">
          <mat-card-content>
            <p>{{subscription.description}}</p>
            <h1 *ngIf="subscription.price !== 0">{{subscription.price}} RON/ luna</h1>
            <h1 *ngIf="subscription.price == 0" class="free">Gratis</h1>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button>Alege</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
  <ng-template #notAvailable>
    {{'subscritionNotAvailable' | translate}}
  </ng-template>

</div>
