// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    // localhost: '6Lco1-QpAAAAAAnbpOY--b54F9P35zcj_Kpi5uuB'
    // prod: '6LcZPBkpAAAAAF_60Zg7M2M99_ElrpkTXcBw3LK4'
    // tslint:disable-next-line:max-line-length
    siteKey: '6LcZPBkpAAAAAF_60Zg7M2M99_ElrpkTXcBw3LK4'
  },
};
export const backendAddress = {
  value: 'https://lupulcreativ.ro/com/'
  // value: 'http://localhost:8000/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
