<div class="contact_container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="desktop">
    <table cellspacing="0" cellpadding="0">
      <tr>
        <td class="right">
          <h1>{{'letsChat' | translate}}</h1>
          <h2>{{'tellMoreAboutIdea' | translate}}</h2>
          <h2>
            {{'emailAt' | translate}} <br>
            lupulcreativ@gmail.com <br>
            {{'callMe' | translate}} <br>
            +40 740 241 423
          </h2>
        </td>
        <td class="left">
          <div *ngIf="!isMailSent" class="form-container">
              <form #form="ngForm">
                <label for="Name">{{'name' | translate}}</label>
                <input type="text" name="Name" id="Name" placeholder="{{'placeholderName' | translate }}" #name>

                <label for="Email">{{'email' | translate}}</label>
                <input type="text" name="Email" id="Email" placeholder="{{'placeholderEmail' | translate }}" #email>

                <label for="Message">{{'message' | translate}}</label>
                <input type="text" name="Message" id="Message" placeholder="{{'placeholderMessage' | translate }}" #message>

                <p class="error">{{errorMessage}}</p>

                <button mat-raised-button (click)="send(form, name.value, email.value, message.value)">{{'sendMessage' | translate}}</button>
              </form>
          </div>
          <div *ngIf="isMailSent">
            <p>{{'thanksForEmail' | translate}}</p>
          </div>

        </td>
      </tr>
    </table>
  </div>
  <div class="mobile">
    <table cellspacing="0" cellpadding="0" *ngIf="!isMailSent">
      <tr>
        <td class="right" style="text-align: center">
          <h1>{{'letsChat' | translate}}</h1>
          <h2>{{'tellMoreAboutIdea' | translate}}</h2>
          <h2>
            {{'emailAt' | translate}} <br>
            lupulcreativ@gmail.com <br>
            {{'callMe' | translate}} <br>
            +40 740 241 423
          </h2>
        </td>
      </tr>
      <tr>
        <td class="left">
          <div *ngIf="!isMailSent" class="form-container">
            <label for="mName">{{'name' | translate}}</label>
            <input type="text" name="mName" id="mName" placeholder="{{'placeholderName' | translate }}" #mname>

            <label for="mEmail">{{'email' | translate}}:</label>
            <input type="text" name="mEmail" id="mEmail" placeholder="{{'placeholderEmail' | translate }}" #memail>

            <label for="mMessage">{{'message' | translate}}</label>
            <input type="text" name="Message" id="mMessage" placeholder="{{'placeholderMessage' | translate }}"
                   #mmessage>

            <p class="error">{{errorMessage}}</p>

            <button mat-raised-button
                    (click)="sendMessage(mname.value, memail.value, mmessage.value)">{{'sendMessage' | translate}}</button>
          </div>
          <div *ngIf="isMailSent">
            <p>{{'thanksForEmail' | translate}}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
