import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const ua = navigator.userAgent;
    const isInstagram = (ua.indexOf('Instagram') > -1);

    if (document.documentElement.classList ){
      if (isInstagram) {
        // @ts-ignore
        // document.getElementById('test').click();
      }
    }
  }

}
