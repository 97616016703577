import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  // tslint:disable-next-line:variable-name
  private lang_Subject = new BehaviorSubject(0);
  // tslint:disable-next-line:variable-name
  current_lang = this.lang_Subject.asObservable();

  constructor(){}

  // tslint:disable-next-line:typedef variable-name
  update_lang(new_lang: number){
    this.lang_Subject.next(new_lang);
  }
}
