import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(private api: ConfigService, private router: Router, private translate: TranslateService) {
  }

  offers = false;
  settings = false;
  addArt = false;
  editArt = false;
  payment = false;
  termsConditions = false;
  isUserAccount = false;
  isArtistAccount = false;
  sideNavComponents = [{
    id: 0,
    name: '',
  }];
  // isContentReady = false;
  popupText = '';
  popupButtonsNotVsible = '';
  selectedId = 0;
  selectedName = '';
  user: any;
  isLoaderVisible = false;

  resetContent(): void {
    this.offers = this.settings = this.addArt = this.editArt = this.payment = this.termsConditions = false;
  }

  changeContent(id: any, name: string): void {
    this.resetContent();
    this.selectedId = id;
    this.selectedName = name;
    switch (id) {
      case 1: {
        this.offers = true;
        break;
      }
      case 3: {
        this.settings = true;
        break;
      }
      case 4: {
        this.addArt = true;
        break;
      }
      case 5: {
        this.editArt = true;
        break;
      }
      case 6: {
        this.payment = true;
        break;
      }
      case 7: {
        this.termsConditions = true;
        break;
      }
      case 8: {
        localStorage.removeItem('token');
        this.router.navigate(['/']);
        break;
      }
    }
  }

  createUserAccount(): void {
    let myOffers = '';
    let myAccount = '';
    let termsCond = '';
    let logout = '';
    this.translate.get('myOffers').subscribe(message => {
      myOffers = message;
    });
    this.translate.get('myAccount').subscribe(message => {
      myAccount = message;
    });
    this.translate.get('termsAndConditionsAccount').subscribe(message => {
      termsCond = message;
    });
    this.translate.get('logOut').subscribe(message => {
      logout = message;
    });
    this.sideNavComponents = [
      {
        id: 1,
        name: myOffers,
      },
      {
        id: 3,
        name: myAccount,
      },
      {
        id: 7,
        name: termsCond,
      },
      {
        id: 8,
        name: logout,
      }
    ];
    this.offers = true;
    this.changeContent(1, 'Ofertele mele');
  }

  createArtistAccount(): void {
    let myArt = '';
    let addArt = '';
    let offers = '';
    let myAcc = '';
    let payment = '';
    let terms = '';
    let logout = '';
    this.translate.get('myArt').subscribe(message => {
      myArt = message;
    });
    this.translate.get('addArt').subscribe(message => {
      addArt = message;
    });
    this.translate.get('receivedOffers').subscribe(message => {
      offers = message;
    });
    this.translate.get('myAccount').subscribe(message => {
      myAcc = message;
    });
    this.translate.get('payment').subscribe(message => {
      payment = message;
    });
    this.translate.get('termsAndConditionsAccount').subscribe(message => {
      terms = message;
    });
    this.translate.get('logOut').subscribe(message => {
      logout = message;
    });
    this.sideNavComponents = [
      {
        id: 5,
        name: myArt,
      },
      {
        id: 4,
        name: addArt,
      },
      {
        id: 1,
        name: offers,
      },
      {
        id: 3,
        name: myAcc,
      },
      {
        id: 6,
        name: payment,
      },
      {
        id: 7,
        name: terms,
      },
      {
        id: 8,
        name: logout,
      }
    ];
    this.editArt = true;
    this.changeContent(5, 'Arta ta');
  }

  getUserInfo(): void {
    this.isLoaderVisible = true;
    this.api.getLoggedUser().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (this.user.role_id === 1) {
            this.createUserAccount();
          } else {
            this.createArtistAccount();
          }
        }
        this.isLoaderVisible = false;
      }, error => {
        console.log('error', error);
        this.isLoaderVisible = false;
        this.router.navigate(['Login']);
        // this.user = JSON.parse('{\n' +
        //   '    "id": 12,\n' +
        //   '    "first_name": "Andreea-Monica",\n' +
        //   '    "last_name": "LUPU",\n' +
        //   '    "email": "andreea.lupu@scriptics.ro",\n' +
        //   '    "phone_number": "0740241423",\n' +
        //   '    "agreed_term_conditions": 1,\n' +
        //   '    "agreed_contacted_via_mail": 1,\n' +
        //   '    "agreed_contacted_via_phone": 1,\n' +
        //   '    "role_id": 3,\n' +
        //   '    "created_at": "2024-07-30T09:02:27.000000Z",\n' +
        //   '    "updated_at": "2024-07-30T09:02:27.000000Z",\n' +
        //   '    "full_name": "Andreea-Monica LUPU",\n' +
        //   '    "role": {\n' +
        //   '        "id": 3,\n' +
        //   '        "role_name": "Artist",\n' +
        //   '        "created_at": null,\n' +
        //   '        "updated_at": null\n' +
        //   '    }\n' +
        //   '}');
        // if (this.user.role_id === 1) {
        //   this.createUserAccount();
        // } else {
        //   this.createArtistAccount();
        // }
      });
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

}
