<div class="account-settings-container" >
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>

<div class="form-container">
<!--  <h1>{{'accountDetails' | translate}}</h1>-->

  <div class="field-container" >
    <div class="field">
      <p><img src="assets/other/svg/pencil-svgrepo-com.svg" alt="edit image">{{'accountDetailsFirstName' | translate}}</p>
      <input [(ngModel)]="loggedInUser.last_name">
    </div>

    <div class="field">
      <p><img src="assets/other/svg/pencil-svgrepo-com.svg" alt="edit image">{{'accountDetailsLastName' | translate}}</p>
      <input [(ngModel)]="loggedInUser.first_name">
    </div>

    <div class="field">
      <p><img src="assets/other/svg/pencil-svgrepo-com.svg" alt="edit image">{{'accountDetailsPhone' | translate}}</p>
      <input [(ngModel)]="loggedInUser.phone_number">
    </div>

    <div class="field">
      <p>{{'accountDetailsMail' | translate}}</p>
      <p>{{loggedInUser.email}}</p>
    </div>

    <div class="field" *ngIf="loggedInUser.role_id === 2">
      <p>{{'accountDetailsNrOfListedPaintings' | translate}}</p>
      <p>{{artObjectsNr}}</p>
    </div>

    <div class="field" *ngIf="loggedInUser.role_id === 2">
      <p>{{'accountDetailsNrOffersReceived' | translate}}</p>
      <p>{{offersNr}}</p>
    </div>

    <div class="field" *ngIf="loggedInUser.role_id === 1">
      <p>{{'accountDetailsNrOffersMade' | translate}}</p>
      <p>{{offersNr}}</p>
    </div>

    <div class="field">
      <p *ngIf="loggedInUser.role_id == 1">{{'accountDetailsUserType' | translate}} {{'userRoleBuyer' | translate}}</p>
      <p *ngIf="loggedInUser.role_id == 2">{{'accountDetailsUserType' | translate}} {{'userRoleArtist' | translate}}</p>
    </div>
    <h2></h2>
<!--    <button mat-raised-button (click)="changeRole()">{{'accountDetailsRequestChangeUserType' | translate}}</button>-->

    <h2>{{'accountDetailsChangePassword' | translate}}</h2>
    <div class="field">
      <p><img src="assets/other/svg/pencil-svgrepo-com.svg" alt="edit image">{{'accountDetailsNewPassword' | translate}}</p>
      <input [(ngModel)]="password" type="password">
    </div>
    <div class="field">
      <p><img src="assets/other/svg/pencil-svgrepo-com.svg" alt="edit image">{{'accountDetailsConfirmNewPassword' | translate}}</p>
      <input [(ngModel)]="confirmPassword" type="password">
    </div>
  </div>
  <div class="error" *ngIf="errorMessage !== ''"><p>{{errorMessage}}</p></div>
  <button mat-raised-button (click)="saveUserData()" class="save-button">{{'accountDetailsSaveModifications' | translate}}</button>
  <button mat-raised-button color="warn" (click)="logout()">
    {{'logOut' | translate}}
  </button>
</div>
</div>
