<div class="terms-conditions-container">
  <h1>{{'termsAndConditionsAccount' | translate}}</h1>

  {{'' | translate}}

  <h2> {{'introductionTC' | translate}}</h2>
  {{'TCp1' | translate}}

  <h3>{{'siteUsage' | translate}}</h3>
  {{'TCp2' | translate}}

  <h3>{{'offerFormInfo' | translate}}</h3>
  {{'TCp3' | translate}}

  <h3>{{'confidentiality' | translate}}</h3>
  {{'TCp4' | translate}}

  <h3>{{'limitResponsibility' | translate}}</h3>
  {{'TCp5' | translate}}

  <h3>{{'appliedLegislatie' | translate}}</h3>
  {{'TCp6' | translate}}

  <h3>{{'modifyTermsConditions' | translate}}</h3>
  {{'TCp7' | translate}}

  <h3>{{'Contact' | translate}}</h3>
  {{'TCp8' | translate}}




  <h1>{{'privacyPolicy' | translate}}</h1>

  {{'lastUpdate' | translate}}
  <br>

  <h3>{{'introductionTC' | translate}}</h3>
  {{'PCp1' | translate}}

  <h3>{{'collectedData' | translate}}</h3>
  {{'PCp2' | translate}}
  <br>
  {{'PCp3' | translate}}

  <h3>{{'dataCollectionScope' | translate}}</h3>
  {{'PCp4' | translate}}

  <h3>{{'dataProtection' | translate}}</h3>
  {{'PCp5' | translate}}

  <h3>{{'dataStorage' | translate}}</h3>
  {{'PCp6' | translate}}

  <h3>{{'accessPersonalData' | translate}}</h3>
  {{'PCp7' | translate}}

  <h3>{{'cookiePolicyPC' | translate}}</h3>
  {{'PCp8' | translate}}

  <h3>{{'modifyPrivacyPolicy' | translate}}</h3>
  {{'' | translate}}

  <h3>{{'Contact' | translate}}</h3>
  {{'PCp10' | translate}}




  <h1>{{'cookiePolicyTitle' | translate}}</h1>

  {{'lastUpdate' | translate}}
  <br>

  <h3>{{'introductionTC' | translate}}</h3>
  <br>
  {{'CPp1' | translate}}

  <h3>{{'whatAreCookies?' | translate}}</h3>
  {{'CPp2' | translate}}

  <h3>{{'typeOfCookies' | translate}}</h3>
  {{'CPp3' | translate}}

  <h3>{{'cookieUsage' | translate}}</h3>
  {{'CPp4' | translate}}

  <h3>{{'cookieThird' | translate}}</h3>
  {{'CPp5' | translate}}

  <h3>{{'cookieModify' | translate}}</h3>
  {{'CPp6' | translate}}

  <h3>{{'Contact' | translate}}</h3>
  {{'CPp7' | translate}}

<!--  <a href="http://google.com" target="_blank" id="test">Google</a>-->


</div>
