import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from '../config/config.service';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private api: ConfigService,  public location: Location,
              private snackBar: MatSnackBar, private translate: TranslateService, private recaptchaV3Service: ReCaptchaV3Service ) { }

  isLogin = true;
  isForgotPass = false;
  message = '';
  errorMessage = '';
  email = '';
  password = '';
  confirmPassword = '';
  isKnowMoreVisible = false;
  isKnowArtist = true;
  isRegisterVisible = false;
  isLoaderVisible = false;
  isBackVisible = true;
  registerSuccessfullMessage = false;

  resetFields(): void {
    this.email = '';
    this.password = '';
    this.confirmPassword = '';
  }
  checkEmail(): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    {
      return true;
    } else {
      this.translate.get('invalidEmail').subscribe(message => {
        this.errorMessage = message;
        return false;
      });
    }
    return false;
  }
  submitLogin(): void {
    this.isLoaderVisible = true;
    this.api.Login(this.email, this.password).subscribe(result => {
      // @ts-ignore
      localStorage.setItem('token', result.access_token);
      this.isLoaderVisible = false;
      this.router.navigate(['Market/0/all']);
    },  err => {
      this.translate.get('errorOccurred').subscribe(message => {
        this.isLoaderVisible = false;
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'error-snack-bar',
            horizontalPosition: 'end', verticalPosition: 'top'});
      });
    });
  }
  submitForgotPass(): void {
    if (this.checkEmail()){
      this.errorMessage = '';
      this.message = 'Poti modifica parola de pe mail';
      this.resetFields();
      this.backLogin();
    }
  }
  resetAllChecks(): void {
    this.isLogin = this.isForgotPass = this.isRegisterVisible = this.isKnowMoreVisible = false;
  }
  register(): void {
    this.resetAllChecks();
    this.isRegisterVisible = true;
  }
  forgotPass(): void {
    this.resetAllChecks();
    this.isForgotPass = true;
  }
  backLogin(): void {
    this.resetAllChecks();
    this.isLogin = true;
  }
  continueNoAccount(): void {
    this.router.navigate(['Offer']);
  }
  knowMore(): void {
    this.isKnowMoreVisible = true;
  }
  back(): void {
    if (this.isLogin) {
      this.location.back();
    } else {
      this.backLogin();
    }
  }
  send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submitContactForm')
      .subscribe((token: string) => {
          // console.log(`Token [${token}] generated`);
          this.submitLogin();
        },
        error => {
          console.log('error', error);
          this.snackBar.open('Recaptcha is not working properly', '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        });
  }

  ngOnInit(): void {
    const route = this.router.url;
    const wasRegisterBefore = localStorage.getItem('registerSuccesfull');

    if (wasRegisterBefore === 'true') {
      this.registerSuccessfullMessage = true;
    }
    localStorage.removeItem('registerSuccesfull');

    if (route.includes('KnowMoreAboutMarket')) {
      this.isBackVisible = false;
      this.knowMore();
    }
    if (route.includes('Register')) {
      this.isBackVisible = false;
      this.register();
    }
  }

}
