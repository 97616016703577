import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PopupComponent} from '../popup/popup.component';
import {ConfigService} from '../config/config.service';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpHeaders} from '@angular/common/http';
import { Output, EventEmitter } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-art',
  templateUrl: './add-art.component.html',
  styleUrls: ['./add-art.component.scss']
})
export class AddArtComponent implements OnInit {

  constructor(public dialog: MatDialog, private api: ConfigService, private snackBar: MatSnackBar, private translate: TranslateService) { }
  @Input() isEditArtVisible: any;
  @Input() artObjectFromEdit: any;
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() loggedInUser: any;

  artObjects = [
    {
      id: 0,
      image: {
        name: ''
      },
      title: '',
      description: '',
      category: '1',
      availability: '0',
      dimension: '',
      technique: '',
      year: ''
    }
  ];
  categories = [
    {
      id: 0,
      name: '',
    },
  ];
  availabilities = [
    {
      id: 0,
      name: 'available'
    },
    {
      id: 1,
      name: 'onOrder'
    }
  ];
  filedata: any;
  isLoaderVisible = false;
  receiverdId = 0;
  errorMessage = '';
  canAddArt = true;

  cancelEdit(): void {
    this.newItemEvent.emit('cancel-edit');
  }
  deleteImage(artObject: any): void {
    this.artObjects.forEach(element => {
      if ( element === artObject ){
        // @ts-ignore
        artObject.image =  {name: ''};
      }
    });
  }
  openDialogDeleteImage(artObject: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {popupText: 'Esti sigur ca vrei sa stergi imaginea?', popupButtonsNotVsible: 'nu'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.deleteImage(artObject);
      }
    });
  }
  openDialogDeleteObject(artObject: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {popupText: 'Esti sigur ca vrei sa stergi obiectul?', popupButtonsNotVsible: 'nu'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.deleteArtObject(artObject.id);
      }
    });
  }
  openDialogFieldsRequired(): void {
    this.dialog.open(PopupComponent, {
      data: {popupText: 'Trebuie sa completezi campurile obligatorii prima data si poi poti adauga un obiect nou', popupButtonsNotVsible: 'da'},
      disableClose: true
    });
  }

  // tslint:disable-next-line:typedef
  fileEvent(artObject: any, e: Event){
    // @ts-ignore
    this.filedata = e.target.files[0];
    this.artObjects.forEach(element => {
      if ( element === artObject ){
        // @ts-ignore
        artObject.image =  e.target.files[0];
      }
    });
  }

  addArt(): void {
    if (this.artObjects[this.artObjects.length - 1].description === '' || this.artObjects[this.artObjects.length - 1].title === ''){
      this.openDialogFieldsRequired();
    } else {
      this.artObjects.push({
        id: this.artObjects[this.artObjects.length - 1].id + 1,
        image: {
          name: ''
        },
        title: '',
        description: '',
        category: '1',
        availability: '1',
        dimension: '',
        technique: '',
        year: ''
      });
    }
  }

  changeAvailability(id: number, event: Event): void {
    // @ts-ignore
    this.artObjects[id].availability = event.target.value.toString();
  }

  changeCategory(id: number, event: Event): void {
    // id daca pe viitor vreeau sa adaug mai multe
    // @ts-ignore
    this.artObjects[0].category = event.target.value.toString();
  }

  checkYear(): boolean {
    if (this.artObjects[0].year === '' || /^\d+$/.test(this.artObjects[0].year)){
      this.errorMessage = '';
      return true;
    } else {
      this.translate.get('yearMustHaveOnlyLetters').subscribe(message => {
        this.errorMessage = message;
      });
      return false;
    }
  }

  verifyForm(): boolean {
    if (this.isEditArtVisible){
      return !!(this.artObjects[0].title && this.artObjects[0].description && this.artObjects[0].dimension);
    } else {
      return !!(this.filedata && this.artObjects[0].title && this.artObjects[0].description && this.artObjects[0].dimension);
    }
  }
  // tslint:disable-next-line:typedef
  onSubmitform(f: ReactiveFormsModule) {
    if (this.checkYear()){
      if (this.verifyForm()){
        this.errorMessage = '';
        const myFormData = new FormData();
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        myFormData.append('painting', this.filedata);
        myFormData.append('title', this.artObjects[0].title);
        myFormData.append('description', this.artObjects[0].description);
        myFormData.append('picture_category_id', this.artObjects[0].category);
        myFormData.append('availability', this.artObjects[0].availability);
        myFormData.append('dimension', this.artObjects[0].dimension);
        myFormData.append('technique', this.artObjects[0].technique);
        myFormData.append('year', this.artObjects[0].year);
        if (this.artObjects[0].id) {
          myFormData.append('id', this.artObjects[0].id.toString());
        }
        this.isLoaderVisible = true;
        this.api.postFile(myFormData).subscribe(data => {
            this.isLoaderVisible = false;
            window.location.reload();
            this.translate.get('uploadSuccessful').subscribe(message => {
              this.snackBar.open(message, '',
                {duration: 3000, panelClass: 'success-snack-bar',
                  horizontalPosition: 'end', verticalPosition: 'top'});
            });
          },
          error => {
            this.isLoaderVisible = false;
            console.log('error', error);
            this.translate.get('errorOccurred').subscribe(message => {
              this.snackBar.open(message, '',
                {duration: 3000, panelClass: 'error-snack-bar',
                  horizontalPosition: 'end', verticalPosition: 'top'});
            });
          });
      }
      else {
        this.translate.get('completeRequiredFields').subscribe(message => {
          this.errorMessage = message;
        });
      }
    }
  }
    deleteArtObject(id: number): void{
    let ct = 0;
    this.artObjects.forEach(element => {
      ct++;
      if (element.id === id){
        this.artObjects.splice(this.artObjects.indexOf(element), 1);
      }
    });
  }
  getUpsertDropdowns(): void {
    this.isLoaderVisible = true;
    this.api.postUpsertDropdowns().subscribe(data => {
      // @ts-ignore
      this.categories = data.painting_categories;
      this.isLoaderVisible = false;
    }, err => {
      this.isLoaderVisible = false;
    });
  }
  checkAddAvailability(): void {
    this.isLoaderVisible = true;
    this.api.getLoggedInArtistArtObjects().subscribe(data => {
        // @ts-ignore
        const addedArt = data.length;
        if (addedArt >= 5) {
          this.canAddArt = false;
        }
        this.isLoaderVisible = false;
        this.getUpsertDropdowns();
      },
      error => {
        console.log('error', error);
        this.isLoaderVisible = false;
      });
  }
  createEditArtObject(): void {
    if (this.artObjectFromEdit != null) {
      this.receiverdId = this.artObjectFromEdit.id;
      this.artObjects[0] = {
        id: this.artObjectFromEdit.id,
        image: {
          name: this.artObjectFromEdit.file_name,
        },
        title: this.artObjectFromEdit.title,
        description: this.artObjectFromEdit.description,
        category: this.artObjectFromEdit.picture_category_id,
        availability: this.artObjectFromEdit.availability,
        dimension: this.artObjectFromEdit.dimension,
        technique: this.artObjectFromEdit.technique,
        year: this.artObjectFromEdit.year
      };
    }
    this.getUpsertDropdowns();
  }
  ngOnInit(): void {
    if (!this.isEditArtVisible) {
      this.checkAddAvailability();
    } else {
      this.createEditArtObject();
    }
  }
}
