import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {EventNumberService} from '../service/eventNumber.service';
import {DOCUMENT} from '@angular/common';
import {Events} from '../shared/Events';
import {CalendarOptions} from '@fullcalendar/angular';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {PaintingDetailsComponent} from '../painting-details/painting-details.component'; // useful for typechecking
import {Renderer2} from '@angular/core';
import {UpcomingEventService} from '../service/upcomingEvent.service';
import {LanguageService} from '../service/language.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:variable-name
  constructor(@Inject(DOCUMENT) private document: Document, private _eventNumber: EventNumberService,
              // tslint:disable-next-line:variable-name
              public dialog: MatDialog, private renderer: Renderer2, private _upcomingEvents: UpcomingEventService,
              // tslint:disable-next-line:variable-name
              private _language: LanguageService, private translate: TranslateService) {
    this.checkLanguage();
  }

  windowScrolled = false;
  eventNr = 0;
  events: Events[] = [{
    id: 0,
    name: '',
    date: '',
    description: '',
    details_shown: false
  }];
  // tslint:disable-next-line:variable-name
  image_heigth = 0;
  month = '';
  today = 0;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // tslint:disable-next-line:variable-name
  nr_of_days = 0;
  // tslint:disable-next-line:variable-name
  list_of_days = [0];
  year = 0;
  // tslint:disable-next-line:variable-name
  first_day = '';
  url1: string[] = [
    '/assets/exihibition1/expo1.jpg',
    '/assets/exihibition1/oameni.jpg',
    '/assets/exihibition1/boyz.jpeg',
    '/assets/exihibition1/lup.jpeg',
    '/assets/exihibition1/oameni4.jpeg',
    '/assets/exihibition1/mom.jpeg',
    '/assets/exihibition1/oameni2.png',
    '/assets/exihibition1/oamnei3.jpg',
    '/assets/exihibition1/picturi1.jpg',
  ];
  url2: string[] = [
    // '/assets/exhibition2/ana.jpg',
    '/assets/exhibition2/castigator.jpg',
    '/assets/exhibition2/oameni.jpg',
    '/assets/exhibition2/oameni2.jpg',
    '/assets/exhibition2/picturi1.jpg',
    '/assets/exhibition2/picturi2.png',
    '/assets/exhibition2/picturi3.png',
    '/assets/exhibition2/people.jpeg',
    '/assets/exhibition2/premiu.jpg',
    '/assets/exhibition2/steve.jpg',
    '/assets/exhibition2/boyz.jpeg',
    '/assets/exhibition2/family.jpeg',
    '/assets/exhibition2/grandparents.jpeg',
  ];
  url3: string[] = [
    '/assets/exhibition3/1.jpg',
    '/assets/exhibition3/1-1.jpg',
    '/assets/exhibition3/5.jpg',
    '/assets/exhibition3/8.jpg',
    '/assets/exhibition3/9.jpg',
    '/assets/exhibition3/4.jpg',
    '/assets/exhibition3/6.jpg',
    '/assets/exhibition3/7.jpg',
    '/assets/exhibition3/2-1.jpg',
  ];
  url4: string[] = [
    '/assets/exhibition4/poze/Lupul Creativ-2-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-4-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-9-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-21-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-22-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-24-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-32-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-33-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-42-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-43-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-45-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-66-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-67-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-71-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-72-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-85-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-96-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-111-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-120-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-121-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-122-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-125-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-131-min.jpg',
    '/assets/exhibition4/poze/Lupul Creativ-132-min.jpg',
  ];
  url5: string[] = [
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-104.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-105.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-106.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-152.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-93.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-65.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-66.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-75.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-115.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-135.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-73.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-41.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-33.JPG',
    '/assets/exhibition5/poze/Lupul Creativ - expozitie-152.JPG',
    // '/assets/exhibition5/poze/',
  ];
  // tslint:disable-next-line:variable-name
  previous_months_days = 0;
  // tslint:disable-next-line:variable-name
  month_index = -1;
  // tslint:disable-next-line:variable-name
  days_left = 0;
  upcommingEvent = {
    day: this._upcomingEvents.day,
    month: this._upcomingEvents.month,
    year: this._upcomingEvents.year,
    location: this._upcomingEvents.location,
    locationHref: this._upcomingEvents.locationHref
  };
  // tslint:disable-next-line:variable-name
  events_planned = this._upcomingEvents.state;
  isEventSelected = false;
  eventSelected = {
    title: '',
    details: '',
    location: '',
    date: '',
    urls: ''
  };
  amrDays = 0;
  amrMinutes = 0;
  amrHours = 0;
  amrSeconds = 0;
  isDateReady = false;
  interval: any;

  GetHeigth(): void {
    // @ts-ignore
    this.image_heigth = document.getElementById('panel-header1').getBoundingClientRect().height;
  }

  // tslint:disable-next-line:variable-name
  GetMonthName(month_id: number): void {
    switch (month_id) {
      case 1: {
        this.month = 'January';
        break;
      }
      case 2: {
        this.month = 'February';
        break;
      }
      case 3: {
        this.month = 'March';
        break;
      }
      case 4: {
        this.month = 'April';
        break;
      }
      case 5: {
        this.month = 'May';
        break;
      }
      case 6: {
        this.month = 'June';
        break;
      }
      case 7: {
        this.month = 'July';
        break;
      }
      case 8: {
        this.month = 'August';
        break;
      }
      case 9: {
        this.month = 'September';
        break;
      }
      case 10: {
        this.month = 'October';
        break;
      }
      case 11: {
        this.month = 'November';
        break;
      }
      case 12: {
        this.month = 'December';
        break;
      }
    }
  }

  GetListOfDays(): void {
    for (let i = 1; i <= this.nr_of_days; i++) {
      this.list_of_days.push(i);
    }
    this.list_of_days.splice(0, 1);
  }

  ConstructDatys(): void {
    this.days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // tslint:disable-next-line:variable-name
    this.previous_months_days = this.days.indexOf(this.first_day);

    for (let i = 0; i < this.previous_months_days; i++) {
      this.list_of_days.push(this.list_of_days[this.list_of_days.length - 1] + 1);
    }
    for (let i = 0; i < this.days.length; i++) {
      this.days[i] = this.days[i].substring(0, 2);
    }
  }

  getHoursUntillEvent(): void {
    const countDownDate = new Date('Jun 2, 2024 13:00:00').getTime();
    // tslint:disable-next-line:only-arrow-functions typedef
    const now = new Date().getTime();
    const distance = countDownDate - now;
    this.amrDays = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.amrHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.amrMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.amrSeconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.amrSeconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.isDateReady = true;
  }

  GetDateDetails(): void {
    const date = new Date();
    this.today = date.getDate();
    const month = date.getMonth() + 1;
    this.month_index = month;
    this.GetMonthName(month);
    this.year = date.getFullYear();
    this.nr_of_days = new Date(this.year, month, 0).getDate();
    const test = new Date(this.year, month, 2, 0, 0, 0);
    this.GetListOfDays();
    this.first_day = this.days[new Date(this.year, date.getMonth()).getDay()];
    this.ConstructDatys();
    // tslint:disable-next-line:typedef
    this.interval = setInterval(() => {
      this.getHoursUntillEvent();
    }, 1000);
  }

  // tslint:disable-next-line:variable-name
  SelectEvent(event_nr: number): void {
    this.eventNr = event_nr;
    this.DisplayedSliderImage();
  }

  ReadMore(event: string): void {
    // tslint:disable-next-line:variable-name
    let element_id = 'read-more' + event;
    // @ts-ignore
    document.getElementById(element_id).style.display = 'block';
    element_id = element_id + '-button';
    // @ts-ignore
    document.getElementById(element_id).style.display = 'none';
    element_id = 'read-less' + event + '-button';
    // @ts-ignore
    document.getElementById(element_id).style.display = 'block';
  }

  ReadLess(event: string): void {
    // tslint:disable-next-line:variable-name
    let element_id = 'read-more' + event;
    // @ts-ignore
    document.getElementById(element_id).style.display = 'none';
    element_id = element_id + '-button';
    // @ts-ignore
    document.getElementById(element_id).style.display = 'block';
    element_id = 'read-less' + event + '-button';
    // @ts-ignore
    document.getElementById(element_id).style.display = 'none';
  }

  DisplayedSliderImage(): void {
  }

  UpCommingEvents(): void {
    window.scroll(0, 0);
    this.upcommingEvent.day = this._upcomingEvents.day;
    this.days_left = this._upcomingEvents.getDaysUntiillEvent();
  }

  OpenPoster(): void {
    let dialogRef: any;
    dialogRef = this.dialog.open(PaintingDetailsComponent, {
      panelClass: 'custom-dialog-container',
      disableClose: true
    });
    dialogRef.componentInstance.isEvents = true;
  }

  checkLanguage(): void {
    this._language.current_lang.subscribe(lang => {
      // tslint:disable-next-line:triple-equals
      if (lang == 0 ) {
        this.translate.use('ro');
      } else {
        this.translate.use('en');
      }
    });
  }
  showEvent(eventNr: number): void {
    this.isEventSelected = true;
    switch (eventNr) {
      case 1: {
        this.eventSelected = {
          title: 'event1Title',
          details: JSON.stringify(['event1Paragraph1', 'event1Paragraph2', 'event1Paragraph3', 'event1Details']),
          location: 'skjd',
          date: 'dsdss',
          urls: JSON.stringify(this.url1)
        };
        break;
      }
      case 2: {
        this.eventSelected = {
          title: 'event2Title',
          details: JSON.stringify(['event2Paragraph1', 'event2Paragraph2', 'event2Paragraph3', 'event2Paragraph4', 'event2Details1', 'event2Details2']),
          location: '',
          date: 'dsdss',
          urls: JSON.stringify(this.url2)
        };
        break;
      }
      case 3: {
        this.eventSelected = {
          title: 'event3Title',
          details: JSON.stringify(['event3Parahraph1', 'event3Parahraph2', 'event3Parahraph3', 'event3Parahraph4', 'event3Details1', 'event3Details2']),
          location: 'looooc',
          date: 'dsdss',
          urls: JSON.stringify(this.url3)
        };
        break;
      }
      case 4: {
        this.eventSelected = {
          title: 'event4Title',
          details: JSON.stringify(['event4Parahraph1', 'event4Parahraph2', 'event4Parahraph3', 'event4Details1-event', 'event4Details2-event']),
          location: '',
          date: 'dsdss',
          urls: JSON.stringify(this.url4)
        };
        break;
      }
      case 5: {
        this.eventSelected = {
          title: 'event5Title',
          details: JSON.stringify(['event5Parahraph1', 'event5Parahraph2', 'event5Parahraph3', 'event5Parahraph4']),
          location: '',
          date: 'dsdss',
          urls: JSON.stringify(this.url5)
        };
        break;
      }
    }
    window.scroll({top: 0, behavior: 'smooth'});
  }
  ngOnInit(): void {
    localStorage.setItem('latestPainting', '');
    this.checkLanguage();
    this.GetDateDetails();
    this.events.push({
      date: '01.06.2019',
      description: 'All participants had a surprise: a givaway;\n' +
        'Everybody participated at the giveaway, while they enjoyed a good coffee and admired the paintings.\n' +
        'The giveaway was a painting, a card and a chocolate.\n' +
        '—————————\n' +
        '—||—\n' +
        'The place remained the same, Rennes Caffee.\n' +
        'Same as last time, family, friends and happiness were were present, but there was also something extra.\n' +
        'This time, the number of the paintings as well as the number of people that came was bigger.\n' +
        'Another surprise was a group of foreign students that came to see the paintings.\n' +
        '\n' +
        'A surprise was prepared for everybody: a giveaway.\n' +
        'Everybody participated and was excited to find out who the winner is.\n' +
        'The giveaway was made of: a painting, a card and. chocolate.\n' +
        'The most appreciated paintings were: “Every sunrise is a gift” and “A Creative Wolf”. \n' +
        '\n' +
        'Radu-Stefan Berghia can tell you a lot more details: “…”',
      id: 1,
      name: 'Second Painting Exhibition',
      details_shown: false
    });
    this.events.splice(0, 1);
    this._eventNumber.current_event.subscribe(res => {
      this.eventNr = res;
    });
    this.UpCommingEvents();
    window.scroll(0, 0);
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
