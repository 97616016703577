import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Paintings} from '../shared/Paintings';
import {ConfigService} from '../config/config.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-offer-lc',
  templateUrl: './offer-lc.component.html',
  styleUrls: ['./offer-lc.component.scss']
})
export class OfferLcComponent implements OnInit {

  constructor(public location: Location, private api: ConfigService, private translate: TranslateService, private router: Router,
              private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) {
  }

  selectedPaining: Paintings = {
    id: 0,
    path: '',
    name: '',
    category: '',
    tags: '',
    availability: 0,
    price: '',
    dimension: '',
    description: '',
    // tslint:disable-next-line:variable-name
    created_at: null,
    // tslint:disable-next-line:variable-name
    updated_at: null
  };
  isLoaderVisible = false;
  message = '';
  errorMessage = '';
  email = '';
  fullName = '';
  phone = '';
  offer = '';
  additionalText = '';
  href = '';
  termsConditions = false;
  agreeData = false;
  artObject: any;
  artist: any;
  isDataSent = false;

  checkEmail(): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    {
      return true;
    } else {
      this.translate.get('invalidEmail').subscribe(message => {
        this.errorMessage = message;
        return false;
      });
    }
    return false;
  }

  isDataCorrect(): boolean {
    if (this.email !== '' && this.fullName !== '' && this.termsConditions && this.agreeData) {
      if (this.checkEmail()) {
        return true;
      }
    } else {
      this.translate.get('completeRequiredFields').subscribe(message => {
        this.errorMessage = message;
        return false;
      });
    }
    return false;
  }

  sendOfferLC(): void {
    // verificari
    if (this.isDataCorrect()) {
      this.isLoaderVisible = true;
      const message = 'Clientul cu email: ' + this.email + ', avand numele ' + this.fullName + ' si nr de tel: ' + this.phone + ' vrea obiectul cu id ul ' + this.selectedPaining.id + '. Additional data: offer - ' + this.offer + ', details - ' + this.additionalText + 'https://lupulcreativ.ro/#/Painting/5/' + this.selectedPaining.id;
      this.api.SendEmail(this.fullName, this.email, message).subscribe(
        data => {
          if (this.selectedPaining.category === '5'){
            this.api.reservePainting(this.selectedPaining.id.toString(), this.email).subscribe(
              reserveddata => {
                // console.log
              },
              error => {
                // tslint:disable-next-line:no-shadowed-variable
                this.translate.get('errorOccurred').subscribe(message => {
                  this.errorMessage = message;
                  this.isLoaderVisible = false;
                });
              });
          }
          this.isLoaderVisible = false;
          this.isDataSent = true;
        },
        error => {
          // tslint:disable-next-line:no-shadowed-variable
          this.translate.get('errorOccurred').subscribe(message => {
            this.errorMessage = message;
            this.isLoaderVisible = false;
          });
        });
    }
  }

  send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submitContactForm')
      .subscribe((token: string) => {
          this.sendOfferLC();
        },
        error => {
          console.log('error', error);
          this.snackBar.open('Recaptcha is not working properly', '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        });
  }

  ngOnInit(): void {
    const localstoragePainting = localStorage.getItem('selectedPainting');
    if (localstoragePainting) {
      this.selectedPaining = JSON.parse(localstoragePainting);
    }
    console.log(this.selectedPaining);
  }

}
