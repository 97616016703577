import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CookiePolicyComponent} from '../cookie-policy/cookie-policy.component';
import {ConfigService} from '../config/config.service';
import { backendAddress } from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent implements OnInit, OnDestroy {
  router: Router;

  constructor(private privateRouter: Router, public dialog: MatDialog, private api: ConfigService,
              // tslint:disable-next-line:variable-name
              private snackBar: MatSnackBar, private translate: TranslateService, private meta: Meta, private meta_title: Title) {
    this.router = privateRouter;
  }

  isLoaderVisible = false;
  selectedCategoryId = 0;
  selectedArtistId = 0;
  selectedArtistName = '';
  paintings = [
    {
      id: 0,
      path: '',
      name: '',
      category: '',
      tags: '',
      availability: '',
      price: '',
      dimension: '',
      description: '',
      created_at: '',
      updated_at: '',
      artist_name: '',
    }
  ];
  categories = [
    { id: 0, name: 'Toate Categoriile' }
  ];
  artists = [{
      id: 0,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      agreed_term_conditions: 0,
      agreed_contacted_via_mail: 0,
      agreed_contacted_via_phone: 0,
      role_id: 0,
      created_at: '',
      updated_at: '',
      full_name: 'Toti artistii',
    }];
  artObjects = [
    {
      id: 0,
      title: '',
      description: '',
      dimension: '',
      year: 0,
      file_name:  '',
      file_path:  ''
    }
  ];
  artObjectsPag = [
    {
      id: 0,
      title: '',
      description: '',
      dimension: '',
      year: 0,
      file_name:  '',
      file_path:  ''
    }
  ];
  paintingsUrls = [''];
  areFiltersVisible = true;
  isUserLoggedIn = false;
  currentPage = 0;
  maxPages = 0;
  paintingsPerPage = 12;
  firstPaintingIndex = 0;
  isDataReady = false;
  userRole = 0;
  user: any;
  isMarketReady = true;
  backendAddress = backendAddress.value;
  email = '';
  agreeData = false;
  saveMailErrorMessage = '';
  saveMailSuccessMessage = '';
  slideIndex = 0;
  isMobile = false;
  interval: any;

  toggleFilterAvailability(): void {
    const filters = document.getElementById('filters');
    if (filters) {
      if (filters.style.opacity === '1'){
        filters.style.opacity = '0';
      } else {
        filters.style.opacity = '1';
      }
    }
  }

  resetPagination(): void{
    this.currentPage = 0;
    this.maxPages = 0;
    this.paintingsPerPage = 12;
    this.firstPaintingIndex = 0;
    this.initiatePages();
  }
  initiatePages(): void {
    this.artObjectsPag = this.artObjects;
    this.maxPages = Math.round(this.artObjects.length / this.paintingsPerPage + 0.5);
    this.firstPaintingIndex = this.artObjects[0].id;
    const paintingsPerPage = [
      {
        id: 0,
        title: '',
        description: '',
        dimension: '',
        year: 0,
        file_name:  '',
        file_path:  ''
      }
    ];
    let ct = 0;
    this.artObjects.forEach(painting => {
      if (ct < this.paintingsPerPage){
        // @ts-ignore
        paintingsPerPage.push(painting);
        ct++;
      }
    });
    this.artObjectsPag = paintingsPerPage;
    this.artObjectsPag.shift();
  }
  nextPage(): void {
    if (this.currentPage < this.maxPages - 1 ) {
      this.artObjectsPag = this.artObjects;
      this.currentPage++;
      this.reindexPaintings();
    }
  }

  previousPage(): void {
    if (this.currentPage > 0){
      this.currentPage--;
      this.artObjectsPag = this.artObjects;
      this.reindexPaintings();
    }
  }
  reindexPaintings(): void {
    // this.is_painting_selected = false;
    const paintingsPerPage = [
      {
        id: 0,
        title: '',
        description: '',
        dimension: '',
        year: 0,
        file_name:  '',
        file_path:  ''
      }
    ];
    let ct = this.paintingsPerPage;

    for (let i = this.currentPage * this.paintingsPerPage; i <= this.artObjectsPag.length - 1; i++){
      if (ct > 0){
        paintingsPerPage.push(this.artObjectsPag[i]);
        ct--;
      }
    }
    this.artObjectsPag = paintingsPerPage;
    this.artObjectsPag.shift();
    window.scroll(0, 0);
  }

  resetFilters(): void {
    this.selectedCategoryId = 0;
    this.selectedArtistId = 0;
    this.router.navigate(['Market/0/all']).then(() => {
      this.getAllArtObjects();
    });
  }
  askOffer(artObject: any): void {
    localStorage.setItem('artObject', JSON.stringify(artObject));
    this.router.navigate(['Offer']);
  }
  showHideFilters(): void {
    if (!this.areFiltersVisible){
      this.areFiltersVisible = true;
      // @ts-ignore
      document.getElementById('arrow').style.rotate = '180deg';
    } else {
      this.areFiltersVisible = false;
      // @ts-ignore
      document.getElementById('arrow').style.rotate = '0deg';
    }
  }
  getArtistName(): void {
    this.api.getUserById(this.selectedArtistId.toString()).subscribe(data => {
        // @ts-ignore
        this.selectedArtistName = data.full_name;
        this.router.navigate(['Market/' + this.selectedArtistId + '/' + this.selectedArtistName.replace(/[\s\/]+/g, '-')]);
        this.actualFilter();
      });
  }
  actualFilter(): void {
    // daca e categorie = 0 => trimit doar artistii
    if (this.selectedCategoryId === 0 && this.selectedArtistId !== 0) {
      this.isLoaderVisible = true;
      this.api.getFilterArtObjectsByArtist(this.selectedArtistId).subscribe(data => {
          if (data) {
            // @ts-ignore
            this.artObjects = data;
            if (this.artObjects.length > 0) {
              this.resetPagination();
            }
            this.isLoaderVisible = false;
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          console.log('error', error);
          this.isLoaderVisible = false;
        }
      );
    }
    // daca e artist = 0 => trimit doar categoriile
    if (this.selectedCategoryId !== 0 && this.selectedArtistId === 0) {
      this.isLoaderVisible = true;
      this.api.getFilterArtObjectsByCategory(this.selectedCategoryId).subscribe(data => {
          if (data) {
            // @ts-ignore
            this.artObjects = data;
            if (this.artObjects.length > 0) {
              this.resetPagination();
            }
            this.isLoaderVisible = false;
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          console.log('error', error);
          this.isLoaderVisible = false;
        }
      );
    }
    // daca si si sunt 0 => trimit get all
    if (this.selectedCategoryId === 0 && this.selectedArtistId === 0) {
      this.apiGetAllArt();
    }
    // else => trimit filtrele cu amandoi
    if (this.selectedCategoryId !== 0 && this.selectedArtistId !== 0) {
      this.isLoaderVisible = true;
      this.api.getFilteredArtObjects(this.selectedCategoryId, this.selectedArtistId).subscribe(data => {
          if (data) {
            // @ts-ignore
            this.artObjects = data;
            if (this.artObjects.length > 0) {
              this.resetPagination();
            }
            this.isLoaderVisible = false;
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          console.log('error', error);
          this.isLoaderVisible = false;
        }
      );
    }
  }
  filter(): void{
    if (this.selectedArtistId !== 0) {
      this.getArtistName();
    } else {
      this.router.navigate(['Market/0/all']);
      this.actualFilter();
    }

  }
  openDialogCookies(): void {
    const isCookieAgreed = localStorage.getItem('agreeCookie');
    if (!isCookieAgreed){
      const dialogRef = this.dialog.open(CookiePolicyComponent, {
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        localStorage.setItem('agreeCookie', result);
      });
    }
  }
  checkIfxArtistExists(): boolean {
    const url: string[] = this.router.url.split('/');
    this.selectedArtistId = Number(url[ url.length - 2]);
    if (this.selectedArtistId !== 0){
      return true;
    } else {
      return false;
    }
  }
  apiGetAllArt(): void {
    this.isLoaderVisible = true;
    this.api.getAllArtObjects().subscribe(data => {
        if (data) {
          // @ts-ignore
          this.artObjects = data.reverse();
          if (this.artObjects.length > 0){
            this.initiatePages();
          }
          this.isLoaderVisible = false;
        }
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        console.log('error', error);
        // this.artObjects = JSON.parse('[\n' +
        //   '    {\n' +
        //   '        "id": 6,\n' +
        //   '        "title": "test",\n' +
        //   '        "description": "test",\n' +
        //   '        "dimension": "test",\n' +
        //   '        "year": 222,\n' +
        //   '        "file_name": "WhatsApp Image 2023-11-20 at 15.55.37.jpeg",\n' +
        //   '        "user_id": 7,\n' +
        //   '        "file_path": "storage\\/art-object\\/6\\/WhatsApp Image 2023-11-20 at 15.55.37.jpeg"\n' +
        //   '    },\n' +
        //   '    {\n' +
        //   '        "id": 7,\n' +
        //   '        "title": "Dulceatza",\n' +
        //   '        "description": "Frumos",\n' +
        //   '        "dimension": "90x60x90",\n' +
        //   '        "year": 1997,\n' +
        //   '        "file_name": "sample2.jepg.jpg",\n' +
        //   '        "user_id": 4,\n' +
        //   '        "file_path": "storage\\/art-object\\/7\\/sample2.jepg.jpg"\n' +
        //   '    },\n' +
        //   '    {\n' +
        //   '        "id": 8,\n' +
        //   '        "title": "bursuc",\n' +
        //   '        "description": "bursuc",\n' +
        //   '        "dimension": "12",\n' +
        //   '        "year": 1331,\n' +
        //   '        "file_name": "169392l-650x487-b-dc4f6516.jpg",\n' +
        //   '        "user_id": 6,\n' +
        //   '        "file_path": "storage\\/art-object\\/8\\/169392l-650x487-b-dc4f6516.jpg"\n' +
        //   '    },\n' +
        //   '    {\n' +
        //   '        "id": 14,\n' +
        //   '        "title": "Pahar dragut",\n' +
        //   '        "description": "Un pahar colorat simpatic rau",\n' +
        //   '        "dimension": "300ml",\n' +
        //   '        "year": 2022,\n' +
        //   '        "file_name": "69.png",\n' +
        //   '        "user_id": 2,\n' +
        //   '        "file_path": "storage\\/art-object\\/14\\/69.png"\n' +
        //   '    },\n' +
        //   '    {\n' +
        //   '        "id": 17,\n' +
        //   '        "title": "Callllll",\n' +
        //   '        "description": "dkjf",\n' +
        //   '        "dimension": "skdj",\n' +
        //   '        "year": 1212,\n' +
        //   '        "file_name": "WhatsApp Image 2023-11-17 at 20.28.22.jpeg",\n' +
        //   '        "user_id": 2,\n' +
        //   '        "file_path": "storage\\/art-object\\/17\\/WhatsApp Image 2023-11-17 at 20.28.22.jpeg"\n' +
        //   '    },\n' +
        //   '    {\n' +
        //   '        "id": 18,\n' +
        //   '        "title": "CAL 2",\n' +
        //   '        "description": "desc",\n' +
        //   '        "dimension": "20",\n' +
        //   '        "year": 2023,\n' +
        //   '        "file_name": "WhatsApp Image 2023-11-17 at 20.28.22.jpeg",\n' +
        //   '        "user_id": 2,\n' +
        //   '        "file_path": "storage\\/art-object\\/18\\/WhatsApp Image 2023-11-17 at 20.28.22.jpeg"\n' +
        //   '    }\n' +
        //   ']');
        this.isLoaderVisible = false;
      });
  }
  getAllArtObjects(): void {
    if (!this.checkIfxArtistExists()) {
      this.apiGetAllArt();
    } else {
      this.actualFilter();
    }
  }
  seeDetails(categoryId: string, id: string): void {
    // console.log(categoryId, id);
    window.scrollTo(0, 0);
    this.router.navigate(['Art/' + categoryId + '/' + id]);
  }
  getFilters(): void {
    this.api.postUpsertDropdowns().subscribe(data => {
      // @ts-ignore
      if (data) {
        // @ts-ignore
        data.painting_categories.forEach(category => {
          this.categories.push(category);
        });
      }
    }, err => {
      // this.categories.push(JSON.parse('{\n' +
      //   '    "painting_categories": [\n' +
      //   '        {\n' +
      //   '            "id": 4,\n' +
      //   '            "name": "Alt ceva"\n' +
      //   '        },\n' +
      //   '        {\n' +
      //   '            "id": 3,\n' +
      //   '            "name": "Desene in creion"\n' +
      //   '        },\n' +
      //   '        {\n' +
      //   '            "id": 2,\n' +
      //   '            "name": "Pictura pe panza"\n' +
      //   '        },\n' +
      //   '        {\n' +
      //   '            "id": 1,\n' +
      //   '            "name": "Pictura pe perete"\n' +
      //   '        }\n' +
      //   '    ]\n' +
      //   '}'))
      this.translate.get('errorOccurred').subscribe(message => {
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'error-snack-bar',
            horizontalPosition: 'end', verticalPosition: 'top'});
      });
    });
    this.api.getArtists().subscribe(artists => {
      // @ts-ignore
      if (artists){
        // @ts-ignore
        artists.forEach(artist => {
          this.artists.push(artist);
        });
      }
    }, err => {
      this.translate.get('errorOccurred').subscribe(message => {
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'error-snack-bar',
            horizontalPosition: 'end', verticalPosition: 'top'});
      });
    });
  }
  getLoggedInUser(): void {
    this.isLoaderVisible = true;
    this.api.getLoggedUser().subscribe(
      user => {
        if (user) {
          this.user = user;
          this.isUserLoggedIn = true;
          // @ts-ignore
          this.userRole = user.role_id;
        }
        this.isLoaderVisible = false;
      }, error => {
        this.isUserLoggedIn = false;
        this.isLoaderVisible = false;
      });
  }
  constructDropdowns(): void {
    this.translate.get('allCategories').subscribe(message => {
      this.categories = [
        { id: 0, name: message }
      ];
    });
    this.translate.get('allArtists').subscribe(message => {
      this.artists = [{
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        agreed_term_conditions: 0,
        agreed_contacted_via_mail: 0,
        agreed_contacted_via_phone: 0,
        role_id: 0,
        created_at: '',
        updated_at: '',
        full_name: message,
      }];
    });
  }
  knowMore(): void {
    console.log('know more');
    // TODO replace all localhost 4200!!
    window.open('https://lupulcreativ.ro/#/KnowMoreAboutMarket', '_blank');
  }

  checkEmail(): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    {
      return true;
    } else {
      this.translate.get('invalidEmail').subscribe(message => {
        this.saveMailErrorMessage = message;
        return false;
      });
    }
    return false;
  }

  saveEmail(): void {
    if (this.checkEmail()){
      if (this.agreeData) {
        this.isLoaderVisible = true;
        this.api.SaveEmailForMarket(this.email).subscribe(data => {
          this.translate.get('youWillBeAnnounced').subscribe(msg => {
            this.saveMailSuccessMessage = msg;
            this.isLoaderVisible = false;
          });
          this.translate.get('youWillBeAnnounced').subscribe(msg => {
            this.snackBar.open(msg, '',
              {duration: 3000, panelClass: 'success-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
          });
        }, err => {
          console.log('err', err);
          this.isLoaderVisible = false;
          this.translate.get('errorOccurred').subscribe(msg => {
            this.snackBar.open(msg, '',
              {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
            this.isLoaderVisible = false;
          });
        });
      } else {
        this.translate.get('acceptDataProcess').subscribe(msg => {
          this.saveMailErrorMessage = msg;
        });
      }
    }
  }

  showSlides(): void {
    let i;
    const slides = document.getElementsByClassName('marketSlides');
    for (i = 0; i < slides.length; i++) {
      if (slides[i]){
        // @ts-ignore
        slides[i].style.display = 'none';
      }
    }
    this.slideIndex++;
    console.log('slideIndex', this.slideIndex);
    if (this.slideIndex > slides.length) {
      this.slideIndex = 1;
    }
    if (slides[this.slideIndex - 1]){
      console.log('aaaaa');
      // @ts-ignore
      slides[this.slideIndex - 1].style.display = 'block';
    }
  }

  // tslint:disable-next-line:typedef
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async ngOnInit(): Promise<void> {
    this.meta_title.setTitle('Marketplace - Lupul Creativ');
    this.meta.updateTag({ name: 'description', content: 'Vizitează galeria noastră formată din operele de artă a mai multor artiști contemporani' });
    this.meta.updateTag({ name: 'keywords', content: 'galerie de artă, picturi, artă originală, lucrări personalizate' });

    this.isLoaderVisible = true;
    // const route = this.router.url;
    // if (route.includes('210')) {
    //   this.isMarketReady = true;
    // } else {
    //   this.isMarketReady = false;
    // }
    if (this.isMarketReady) {
      this.constructDropdowns();
      this.getLoggedInUser();
      this.getAllArtObjects();
      // this.artObjects = JSON.parse('[\n' +
      //   '    {\n' +
      //   '        "id": 6,\n' +
      //   '        "title": "test",\n' +
      //   '        "description": "test",\n' +
      //   '        "dimension": "test",\n' +
      //   '        "year": 222,\n' +
      //   '        "file_name": "WhatsApp Image 2023-11-20 at 15.55.37.jpeg",\n' +
      //   '        "user_id": 1,\n' +
      //   '        "file_path": "storage\\/art-object\\/6\\/WhatsApp Image 2023-11-20 at 15.55.37.jpeg"\n' +
      //   '    },\n' +
      //   '    {\n' +
      //   '        "id": 7,\n' +
      //   '        "title": "Dulceatza",\n' +
      //   '        "description": "Frumos",\n' +
      //   '        "dimension": "90x60x90",\n' +
      //   '        "year": 1997,\n' +
      //   '        "file_name": "sample2.jepg.jpg",\n' +
      //   '        "user_id": 1,\n' +
      //   '        "file_path": "storage\\/art-object\\/7\\/sample2.jepg.jpg"\n' +
      //   '    },\n' +
      //   '    {\n' +
      //   '        "id": 8,\n' +
      //   '        "title": "bursuc",\n' +
      //   '        "description": "bursuc",\n' +
      //   '        "dimension": "12",\n' +
      //   '        "year": 1331,\n' +
      //   '        "file_name": "169392l-650x487-b-dc4f6516.jpg",\n' +
      //   '        "user_id": 1,\n' +
      //   '        "file_path": "storage\\/art-object\\/8\\/169392l-650x487-b-dc4f6516.jpg"\n' +
      //   '    },\n' +
      //   '    {\n' +
      //   '        "id": 14,\n' +
      //   '        "title": "Pahar dragut",\n' +
      //   '        "description": "Un pahar colorat simpatic rau",\n' +
      //   '        "dimension": "300ml",\n' +
      //   '        "year": 2022,\n' +
      //   '        "file_name": "69.png",\n' +
      //   '        "user_id": 1,\n' +
      //   '        "file_path": "storage\\/art-object\\/14\\/69.png"\n' +
      //   '    },\n' +
      //   '    {\n' +
      //   '        "id": 17,\n' +
      //   '        "title": "Callllll",\n' +
      //   '        "description": "dkjf",\n' +
      //   '        "dimension": "skdj",\n' +
      //   '        "year": 1212,\n' +
      //   '        "file_name": "WhatsApp Image 2023-11-17 at 20.28.22.jpeg",\n' +
      //   '        "user_id": 1,\n' +
      //   '        "file_path": "storage\\/art-object\\/17\\/WhatsApp Image 2023-11-17 at 20.28.22.jpeg"\n' +
      //   '    },\n' +
      //   '    {\n' +
      //   '        "id": 18,\n' +
      //   '        "title": "CAL 2",\n' +
      //   '        "description": "desc",\n' +
      //   '        "dimension": "20",\n' +
      //   '        "year": 2023,\n' +
      //   '        "file_name": "WhatsApp Image 2023-11-17 at 20.28.22.jpeg",\n' +
      //   '        "user_id": 1,\n' +
      //   '        "file_path": "storage\\/art-object\\/18\\/WhatsApp Image 2023-11-17 at 20.28.22.jpeg"\n' +
      //   '    }\n' +
      //   ']');
      // this.openDialogCookies();
      this.getFilters();
    }
    this.isDataReady = true;

    if (window.innerWidth > 425) {
      this.isMobile = false;
      this.paintingsUrls = ['assets/me/cs1.jpg', 'assets/me/cs2.jpg', 'assets/me/cs3.jpg', 'assets/me/cs4.jpg', 'assets/me/cs5.jpg', 'assets/me/cs6.jpg', 'assets/me/cs7.jpg'];
    } else {
      this.isMobile = true;
      this.paintingsUrls = ['assets/me/csp1.png', 'assets/me/csp2.png', 'assets/me/csp3.png', 'assets/me/csp4.png', 'assets/me/csp5.png', 'assets/me/csp6.png', 'assets/me/csp7.png'];
    }
    await this.delay(1000);
    this.isLoaderVisible = false;

    this.showSlides();
    this.interval = setInterval(() => {
      this.showSlides();
    }, 3000); // 3000
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
