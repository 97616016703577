import {Component, Input, OnInit} from '@angular/core';
import {PopupComponent} from '../popup/popup.component';
import {MatDialog} from '@angular/material/dialog';
import {environment as env} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../config/config.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  constructor(public dialog: MatDialog, private http: HttpClient,
              private api: ConfigService, private snackBar: MatSnackBar, private router: Router, private translate: TranslateService) { }

  @Input() loggedInUser: any;

  isLoaderVisible = false;
  password = '';
  confirmPassword = '';
  errorMessage = '';
  artObjectsNr = 0;
  offersNr = 0;

  changeRole(): void {
    const name = 'LC';
    const mail = 'lupulcreativ@gmail.com';
    const message = 'Userul cu id-ul: ' + this.loggedInUser.id + 'si numele' + this.loggedInUser.full_name + ' doreste schimbarea rolului';
    this.isLoaderVisible = true;
    this.api.SendEmail(name, mail, message).subscribe(
      data => {
        // tslint:disable-next-line:no-shadowed-variable
        this.translate.get('requestSentSuccessfully').subscribe(message => {
          this.snackBar.open(message , '',
            {duration: 3000, panelClass: 'success-snack-bar',  horizontalPosition: 'end', verticalPosition: 'top'});
          this.isLoaderVisible = false;
        });
      },
      error => {
        // tslint:disable-next-line:no-shadowed-variable
        this.translate.get('errorOccurred').subscribe(message => {
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'error-snack-bar',  horizontalPosition: 'end', verticalPosition: 'top'});
          this.isLoaderVisible = false;
        });
      });
  }
  logout(): void {
    // this.isLoaderVisible = true;
    localStorage.removeItem('token');
    this.router.navigate(['/']);

  }
  checkPasswords(): boolean {
    return this.password === this.confirmPassword;
  }
  actualSaveData(): void {
    this.isLoaderVisible = true;
    this.api.postUserEdit(this.loggedInUser.id, this.loggedInUser.first_name, this.loggedInUser.last_name,
      this.loggedInUser.phone_number, this.password).subscribe(data => {
      this.translate.get('modificationsSuccessfullyMade').subscribe(message => {
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'success-snack-bar',  horizontalPosition: 'end', verticalPosition: 'top'});
        this.isLoaderVisible = false;
        this.errorMessage = '';
        location.reload();
      });
    }, err => {
      console.log('err', err);
      this.translate.get('errorOccurred').subscribe(message => {
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'error-snack-bar',  horizontalPosition: 'end', verticalPosition: 'top'});
        this.errorMessage = '';
        this.isLoaderVisible = false;
      });
    });
  }

  saveUserData(): void {
    // daca parolele nu s goale
    if (this.password !== '' && this.confirmPassword !== '') {
      if (this.checkPasswords()) {
        if (this.password.length < 10) {
          this.translate.get('passwordMin10').subscribe(message => {
            this.errorMessage = message;
          });
        } else {
          this.actualSaveData();
        }
      } else {
        this.translate.get('passwordsMustBeIdentical').subscribe(message => {
          this.errorMessage = message;
        });
      }
    } else {
      this.actualSaveData();
    }
  }
  getNrOfArtObjects(): void{
    this.isLoaderVisible = true;
    this.api.getLoggedInArtistArtObjects().subscribe(data => {
        // @ts-ignore
        const addedArt = data.length;
        this.artObjectsNr = addedArt;
        this.isLoaderVisible = false;
      },
      error => {
        console.log('error', error);
        this.isLoaderVisible = false;
      });
  }
  getNrOfOffers(): void {
    this.api.getMyOffers().subscribe(data => {
        // @ts-ignore
        const offers = data.length;
        this.offersNr = offers;
        this.isLoaderVisible = false;
      },
      error => {
        console.log('getLoggedInUserArtObjects error', error);
        this.isLoaderVisible = false;
      });
  }
  ngOnInit(): void {
    this.getNrOfArtObjects();
    this.getNrOfOffers();
  }
}
