import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from '../config/config.service';
import {Paintings} from '../shared/Paintings';
import { Location } from '@angular/common';
import { backendAddress } from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-painting-page',
  templateUrl: './painting-page.component.html',
  styleUrls: ['./painting-page.component.scss'],
})
export class PaintingPageComponent implements OnInit {

  constructor(private router: Router, private api: ConfigService, private location: Location,
              private snackBar: MatSnackBar, private translate: TranslateService) { }

  paintingId = 0;
  paintingCategory = 0;
  isLoaderVisible = false;
  isPaintingPopupVisible = false;
  paintings: Paintings[] = [];
  selectedPainting = {
    id: 0,
    path: '',
    name: '',
    category: '',
    tags: '',
    availability: 0,
    price: '',
    dimension: '',
    technique: '',
    description: '',
    // tslint:disable-next-line:variable-name
    created_at: null,
    // tslint:disable-next-line:variable-name
    updated_at: null,
    reservations_count: 0
  };
  // tslint:disable-next-line:variable-name
  is_painting_selected = false;
  // tslint:disable-next-line:variable-name
  is_painting_selected_notCanvas = false;
  // tslint:disable-next-line:variable-name
  selected_frame = '';
  typeArt = '';
  artistName = '';
  artistEmail = '';
  artistPhone = '';
  isUserArtist = false;
  lc = true;
  artObject: any;

  togglePopup(): void {
    this.isPaintingPopupVisible = !this.isPaintingPopupVisible;
  }

  removeBordersFromRooms(): void {
    for (let i = 1; i <= 6; i++) {
      if (document.getElementById('room' + i.toString())) {
        // @ts-ignore
        document.getElementById('room' + i.toString()).style.border = 'none';
      }
      if (document.getElementById('resp-room' + i.toString())) {
        // @ts-ignore
        document.getElementById('resp-room' + i.toString()).style.border = 'none';
      }
    }
  }

  // tslint:disable-next-line:variable-name
  ChangRoom(room_nr: number): void {
    this.removeBordersFromRooms();

    if (document.getElementById('room' + room_nr)){
      // @ts-ignore
      document.getElementById('room' + room_nr).style.border = '2px solid rgb(40, 167, 122)';
    }

    if (document.getElementById('resp-room' + room_nr)){
      // @ts-ignore
      document.getElementById('resp-room' + room_nr).style.border = '2px solid rgb(40, 167, 122)';
    }

    // @ts-ignore
    document.getElementById('room-for-painting').style.backgroundImage = 'url(\'../assets/room/room' + room_nr + '.jpg\')';
    // @ts-ignore
    document.getElementById('responsive_room-for-painting').style.backgroundImage = 'url(\'../assets/room/room' + room_nr + '.jpg\')';
    // @ts-ignore
    document.getElementById('framed-painting').style.display = 'block';
    // @ts-ignore
    document.getElementById('responsive_framed-painting').style.display = 'block';
  }

  ShowPainting(): void {
    this.togglePopup();
  }
  removeBordersFromFrames(): void {
    for (let i = 1; i <= 3; i++) {
      if (document.getElementById('frame' + i.toString())) {
        // @ts-ignore
        document.getElementById('frame' + i.toString()).style.border = 'none';
      }
      if (document.getElementById('resp-frame' + i.toString())) {
        // @ts-ignore
        document.getElementById('resp-frame' + i.toString()).style.border = 'none';
      }
    }
  }

  // tslint:disable-next-line:variable-name
  ChangFrame(frame_nr: string): void {
    this.removeBordersFromFrames();

    if (document.getElementById('frame' + frame_nr)){
      // @ts-ignore
      document.getElementById('frame' + frame_nr).style.border = '2px solid rgb(40, 167, 122)';
    }

    if (document.getElementById('resp-frame' + frame_nr)){
      // @ts-ignore
      document.getElementById('resp-frame' + frame_nr).style.border = '2px solid rgb(40, 167, 122)';
    }

    this.selected_frame = frame_nr;
  }
  getPaintingDetails(): void {
    const id = this.router.url.split('/');
    this.typeArt = id[1];
    this.paintingCategory = Number(id[2]);
    this.paintingId = Number(id[3]);
  }

  getPainting(): void {
    this.isLoaderVisible = true;
    this.api.getPaintingById(this.paintingId.toString()).subscribe(
      data => {
        // @ts-ignore
        this.selectedPainting = data.data;
        // @ts-ignore
        this.selectedPainting.technique = data.data.tags;
        this.artistName = 'Andreea Lupu';
        this.artistEmail = 'lupulcreativ@gmail.com';
        this.artistPhone = '+40 740 241 423';
        this.checkPaintingCategory();
        this.isLoaderVisible = false;
      },
      error => {
        console.log('oopss', error);
        this.translate.get('noPaintingFound').subscribe(message => {
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'error-snack-bar',
              horizontalPosition: 'end', verticalPosition: 'top'});
          this.isLoaderVisible = false;
        });
      });
  }

  checkPaintingCategory(): void {
    // TODO aici adauga id pentru desene (ca sa fie tot ca si picturile)
    if (this.selectedPainting.category === '1'){
      this.is_painting_selected = true;
      this.is_painting_selected_notCanvas = false;
    } else {
      this.is_painting_selected = false;
      this.is_painting_selected_notCanvas = true;
    }
  }

  toGalerry(): void {
    this.location.back();
  }

  placeOffer(): void {
    if (this.lc) {
      localStorage.setItem('selectedPainting', JSON.stringify(this.selectedPainting));
      this.router.navigate(['OfferLupulCreativ']);
    } else {
    localStorage.setItem('artObject', JSON.stringify(this.artObject));
    this.router.navigate(['Offer']);
  }
  }
  getArtObject(): void {
    this.isLoaderVisible = true;
    this.api.getArtObjectById(String(this.paintingId)).subscribe(data => {
      this.artObject = data;
      // @ts-ignore
      this.selectedPainting.id = data.id;
        // @ts-ignore
      this.selectedPainting.path = backendAddress.value + data.file_path;
        // @ts-ignore
      this.selectedPainting.name = data.title;
        // @ts-ignore
      this.selectedPainting.category = data.picture_category_id.toString();
        // @ts-ignore
      this.selectedPainting.tags = data.tags;
        // @ts-ignore
      this.selectedPainting.availability = data.availability;
        // @ts-ignore
      this.selectedPainting.price = data.price;
        // @ts-ignore
      this.selectedPainting.dimension = data.dimension;
        // @ts-ignore
      this.selectedPainting.description = data.description;
        // @ts-ignore
      this.selectedPainting.technique = data.technique;
        // @ts-ignore
      this.selectedPainting.created_at = data.created_at;
        // @ts-ignore
      this.selectedPainting.updated_at = data.updated_at;
        // @ts-ignore
      this.artistName = data.user.full_name;
        // @ts-ignore
      this.artistEmail = data.user.email;
        // @ts-ignore
      this.artistPhone = data.user.phone_number;
      this.checkPaintingCategory();
      this.isLoaderVisible = false;
    },
      error => {
        console.log('error', error);
        this.translate.get('noSelectedArtFound').subscribe(message => {
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
          this.isLoaderVisible = false;
        });
      });
  }
  open(link: string): void{
    window.open('https://www.' + link);
  }
  checkUser(): void {
    this.api.getLoggedUser().subscribe(user => {
      // @ts-ignore
      if (user && user.role_id === 2){
        this.isUserArtist = true;
      }
    },
      error => {
          this.isUserArtist = false;
    });
  }
  ngOnInit(): void {
    this.checkUser();
    this.getPaintingDetails();
    if (this.typeArt === 'Painting') {
      this.lc = true;
      this.getPainting();
    } else {
      this.lc = false;
      this.getArtObject();
    }
  }
}
