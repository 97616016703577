<div class="market-container" id="market-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div *ngIf="!isMarketReady">
    <!--    <img src="assets/me/cs7.jpg" class="market-img" id="coming-soon-desktop">-->
    <!--    <img src="assets/me/ComingSoon.png" class="market-img" id="coming-soon-desktop">-->
    <!--    <img src="assets/me/comingSoonMobile.png" class="market-img" id="coming-soon-mobile">-->


    <div class="announce-market">
      <div class="slideshow-container">
        <div class="marketSlides fade" *ngFor="let url of paintingsUrls">
          <img [src]="url" alt="market slides" style="width:100%">
        </div>
      </div>
      <div class="mail-container">
        <table class="button-table" *ngIf="!isMobile">
          <tr>
            <td>
              <div *ngIf="saveMailSuccessMessage !== ''">
                <p class="success">{{saveMailSuccessMessage}}</p>
              </div>
              <div *ngIf="saveMailSuccessMessage === ''">
                <div class="announce">
                  <p>{{'announceWhenStart' | translate}}</p>
                  <div style="display: flex; justify-content: center; align-items: center;">
                    <img src="assets/other/mail.svg" alt="mail icon" style="height: 30px; margin-right: 5px;">
                    <input [(ngModel)]="email" placeholder="example@art.com" style="width: 30%;">
                    <button mat-raised-button (click)="saveEmail()" class="button">{{'sendMessage' | translate}}</button>
                  </div>
                </div>
                <p class="error" *ngIf="saveMailErrorMessage">{{saveMailErrorMessage}}</p>

                <p><input type="checkbox" [(ngModel)]="agreeData"
                          style="width: fit-content;">{{'agreeWithDataProcessing' | translate}}</p>

                <button mat-button class="table-buttons" (click)="knowMore()">
                  {{'knowMore' | translate}}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <table class="button-table" *ngIf="isMobile">
      <tr>
        <td>
          <div *ngIf="saveMailSuccessMessage !== ''">
            <p class="success">{{saveMailSuccessMessage}}</p>
          </div>
          <div *ngIf="saveMailSuccessMessage === ''">
            <div class="announce">
              <p>{{'announceWhenStart' | translate}}</p>
              <div style="display: flex; justify-content: center; align-items: center;">
                <img src="assets/other/mail.svg" alt="mail icon" style="height: 30px; margin-right: 5px;">
                <input [(ngModel)]="email" placeholder="example@art.com" style="width: 30%;">
                <button mat-raised-button (click)="saveEmail()" class="button">{{'sendMessage' | translate}}</button>
              </div>
            </div>
            <p class="error" *ngIf="saveMailErrorMessage">{{saveMailErrorMessage}}</p>

            <p><input type="checkbox" [(ngModel)]="agreeData"
                      style="width: fit-content;">{{'agreeWithDataProcessing' | translate}}</p>

            <button mat-button class="table-buttons" (click)="knowMore()">
              {{'knowMore' | translate}}
            </button>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="isMarketReady">
    <div *ngIf="isDataReady">
      <div *ngIf="user">
        <p class="username"> {{'welcome' | translate}} {{user.full_name}}!</p>
      </div>
      <div class="actions">
        <div class="actions-container">
          <button mat-button (click)="router.navigate(['MyAccount'])" *ngIf="isUserLoggedIn">
            {{'myAccount' | translate}}
          </button>

          <button mat-button (click)="router.navigate(['Login'])" *ngIf="!isUserLoggedIn">
            {{'logIn' | translate}}
          </button>

          <button mat-button (click)="toggleFilterAvailability()"><img src="assets/other/svg/setting.png" alt="settongs button"
                                                                       style="height: 20px"></button>
          <div id="filters" class="filters-wrapper">
            <div class="action-div" id="reset-filters">
              <button mat-button (click)="resetFilters()"><img src="assets/other/svg/undo.png" alt="undo svg"></button>
            </div>

            <div class="action-div">
              <!--              <label>{{'category' | translate}}</label>-->
              <select [(ngModel)]="selectedCategoryId" (change)="filter()">
                <option *ngFor="let category of categories"
                        [ngValue]="category.id">{{category.name | translate}}</option>
              </select>
            </div>

            <div class="action-div">
              <!--              <label>{{'artist' | translate}}</label>-->
              <select [(ngModel)]="selectedArtistId" (change)="filter()">
                <option *ngFor="let artist of artists" [ngValue]="artist.id">{{artist.full_name}}</option>
              </select>
            </div>
          </div>

        </div>
        <div class="actions-container-mobile">
          <div id="filters-mobile" class="filters-wrapper-mobile">
            <div class="action-div">
              <select [(ngModel)]="selectedCategoryId" (change)="filter()">
                <option *ngFor="let category of categories"
                        [ngValue]="category.id">{{category.name | translate}}</option>
              </select>
            </div>

            <div class="action-div">
              <!--              <label>{{'artist' | translate}}</label>-->
              <select [(ngModel)]="selectedArtistId" (change)="filter()">
                <option *ngFor="let artist of artists" [ngValue]="artist.id">{{artist.full_name}}</option>
              </select>
            </div>
          </div>
          <button mat-raised-button (click)="router.navigate(['MyAccount'])" *ngIf="isUserLoggedIn">
            {{'myAccount' | translate}}
          </button>

          <button mat-raised-button (click)="router.navigate(['Login'])" *ngIf="!isUserLoggedIn">
            {{'logIn' | translate}}
          </button>
        </div>

        <!--        <img src="assets/other/arrow-down-sign-to-navigate.svg" class="down-arrow" id="arrow" (click)="showHideFilters()">-->
      </div>

      <div class="paginator" *ngIf="artObjects.length > 0 && maxPages > 1">
        <button (click)="previousPage()" [ngClass]="currentPage == 0 ? 'disabled' : ''"><img
          src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(90deg);"></button>

        <p class="disabled" *ngIf="currentPage === 0"></p>
        <p class="disabled" *ngIf="currentPage > 0">{{currentPage}}</p>
        <p>{{currentPage + 1}}</p>
        <p class="disabled" *ngIf="currentPage + 2 <= maxPages">{{currentPage + 2}}</p>
        <p class="disabled" *ngIf="currentPage === maxPages - 1"></p>

        <button (click)="nextPage()" [ngClass]="currentPage == maxPages - 1 ? 'disabled' : ''"><img
          src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(-90deg);"></button>
      </div>

      <div class="categories" *ngIf="artObjects.length > 0">
        <div class="painting" *ngFor="let artObject of artObjectsPag">
          <div class="painting-details">
            <div class="painting-details-overlay" (click)="seeDetails('1', artObject.id.toString())">
              <button mat-button class="details">
                <img src="assets/other/svg/eye.png" height="40px" class="svg">
              </button>
            </div>
            <img [src]="backendAddress +  artObject.file_path" class="artObjectImage">
            <!--            <img src="assets/gallery/108.jpg" class="artObjectImage">-->
          </div>

          <div class="painting-details-actions">
            <button mat-raised-button (click)="seeDetails('1', artObject.id.toString())"
                    class="details-mobile simple-button">{{'seeDetails' | translate}}</button>
            <button mat-raised-button *ngIf="userRole === 1 || !user" (click)="askOffer(artObject)"
                    class="cere-oferta simple-button">{{'askOffer' | translate}}</button>
          </div>
        </div>
      </div>

      <div class="paginator" *ngIf="artObjects.length > 0 && maxPages > 1">
        <button (click)="previousPage()" [ngClass]="currentPage == 0 ? 'disabled' : ''"><img
          src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(90deg);"></button>

        <p class="disabled" *ngIf="currentPage === 0"></p>
        <p class="disabled" *ngIf="currentPage > 0">{{currentPage}}</p>
        <p>{{currentPage + 1}}</p>
        <p class="disabled" *ngIf="currentPage + 2 <= maxPages">{{currentPage + 2}}</p>
        <p class="disabled" *ngIf="currentPage === maxPages - 1"></p>

        <button (click)="nextPage()" [ngClass]="currentPage == maxPages - 1 ? 'disabled' : ''"><img
          src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(-90deg);"></button>
      </div>

      <div *ngIf="artObjects.length == 0" style="text-align: center; margin-top: 5%">
        {{'noResultsFound' | translate}}
      </div>
    </div>
  </div>
</div>
