import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nft',
  templateUrl: './nft.component.html',
  styleUrls: ['./nft.component.scss']
})
export class NftComponent implements OnInit {

  constructor() { }

  nftProjects = [
    {
      id: 1,
      name: 'WorldOfWolfpack',
      heroImageUrl: 'assets/nft/nft_promoting/begginersRound.jpg',
      imgUrl1: 'assets/nft/wolves/26.jpg',
      imgUrl2: 'assets/nft/wolves/39.jpg',
      imgUrl3: 'assets/nft/wolves/48.jpg',
    }
  ];

  ngOnInit(): void {
  }

}
