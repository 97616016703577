<div class="seasonal-objects-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="description">
    <h1>
      {{'woodenChristmasGlobes' | translate}}
    </h1>
    <p style="text-align: center">
      {{'christmasParagraph1' | translate}}
    </p>

    <p style="margin: 0">
      {{'christmasParagraph2' | translate}}
      {{'christmasParagraph3' | translate}}
    </p>

    <p>
     {{'christmasParagraph4' | translate}}
    </p>

    <p>
       {{'christmasParagraph5' | translate}}
    </p>

    <p style="text-align: right; font-style: italic;">
      {{'christmasParagraph6' | translate}}
    </p>
  </div>

  <div class="seasonal-objects">
    <div class="object" *ngFor="let object of objects">

      <div class="painting">
        <div class="painting-overlay" [routerLink]="['/Painting', object.category, object.id]">
          <button mat-button class="details">
            <img alt="detail eye svg" src="assets/other/svg/eye.png" height="40px" class="svg">
          </button>
        </div>

        <img [src]="object.path" alt="Globuri de Craciun pictate pe lemn" class="product-image">
      </div>
      <div class="availability-container">
        <div *ngIf="object.availability == 0" class="flex">
          <div class="available"></div>{{'available' | translate}}
        </div>
        <div *ngIf="object.availability == 1" class="flex">
          <div class="not-available"></div>{{'notAvailable' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
