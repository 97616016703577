<div class="register-container">
  <div class="from">
    <h1>{{'createAccount' | translate}}</h1>

    <form #form="ngForm">
      <input type="email" placeholder="{{'email' | translate}}" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
      <input type="phone" placeholder="{{'phoneNumber' | translate}}" name="phone" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}">

      <input type="text" placeholder="{{'lastName' | translate}}" name="name" [(ngModel)]="firstName" [ngModelOptions]="{standalone: true}">
      <input type="text" placeholder="{{'firstName' | translate}}" name="last_name" [(ngModel)]="lastName" [ngModelOptions]="{standalone: true}">

      <select [(ngModel)]="roleId" [ngModelOptions]="{standalone: true}">
        <option *ngFor="let role of roleIds"
                [ngValue]="role.id">{{role.name | translate}}</option>
      </select>

      <!--      <input type="password" placeholder="Parola*" name="password" [(ngModel)]="password">-->
      <input type="password" placeholder="{{'password' | translate}}" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">

      <input type="password" placeholder="{{'confirmPassword' | translate}}" name="conf-password" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}">

      <p *ngIf="errorMessage" style="color: red">{{errorMessage}}</p>

      <p><input type="checkbox" [(ngModel)]="termsConditions" [ngModelOptions]="{standalone: true}" style="width: fit-content;">{{'agreeWith' | translate}} <a [routerLink]="'/TermsConditions'">{{'termsAndConditions' | translate}}</a></p>

      <p><input type="checkbox" [(ngModel)]="agreeData" [ngModelOptions]="{standalone: true}" style="width: fit-content;">{{'agreeWithDataProcessing' | translate}}</p>

      <p><input type="checkbox" [(ngModel)]="phoneAndMail" [ngModelOptions]="{standalone: true}" style="width: fit-content;">{{'contactViaPhoneAndEmail' | translate}}</p>

      <p><input type="checkbox" [(ngModel)]="justMail" [ngModelOptions]="{standalone: true}" style="width: fit-content;">{{'contactJustMail' | translate}}</p>

      <button mat-raised-button (click)="send(form)">{{'register' | translate}}</button>
    </form>
  </div>

</div>
