import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {HttpParams} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from '../service/language.service';
import {NgForm} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(private api: ConfigService, private translate: TranslateService, private _language: LanguageService,
              private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) {
    this.checkLanguage();
  }

  isLoaderVisible = false;
  isMailSent = false;
  errorMessage = '';
  // tslint:disable-next-line:typedef
  validateEmail(email: any) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  send(form: NgForm, name: string, email: string, message: string): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submitContactForm')
      .subscribe((token: string) => {
        // console.log(`Token [${token}] generated`);
        this.sendMessage(name, email, message);
      },
        error => {
        console.log('error', error);
        this.snackBar.open('Recaptcha is not working properly', '',
          {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        });
  }

  sendMessage(name: string, email: string, message: string): void {
    if (name === '' || email === '' || message === '') {
      this.translate.get('completeRequiredFields').subscribe( msg => {
        this.errorMessage =  msg;
      });
    } else {
      if (this.validateEmail(email)){
        this.isLoaderVisible = true;
        this.errorMessage = '';
        this.api.SendEmail(name, email, message).subscribe(
          data => {
            this.isLoaderVisible = false;
            this.isMailSent = true;
          },
          error => {
            console.log('oops', error);
            this.translate.get('errorOccurred').subscribe(msg => {
              this.errorMessage = msg;
            });

            this.isLoaderVisible = false;
            this.isMailSent = false;
          });
      }
      else {
        this.translate.get('invalidEmail').subscribe(msg => {
          this.errorMessage = msg;
        });
      }
    }
  }
  initialize(i: any, t: any): void {
    let e;
    i.getElementById(t) ? this.initFreshChat() : ((e = i.createElement('script')).id = t, e.async = !0, e.src = 'https://wchat.eu.freshchat.com/js/widget.js', e.onload = this.initFreshChat, i.head.appendChild(e));
  }


  // tslint:disable-next-line:typedef
  test() {
  }
  initiateCall(): void {
    this.test();
    // tslint:disable-next-line:max-line-length
    this.initialize(document, 'Freshdesk Messaging-js-sdk');
  }

  initFreshChat(): void {
    (window as any).fcWidget.init({
      token: '8905d28d-bdb2-4578-8bf3-d53a135f9215',
      host: 'https://wchat.eu.freshchat.com'
    });
  }

  checkLanguage(): void {
    this._language.current_lang.subscribe(lang => {
      // tslint:disable-next-line:triple-equals
      if (lang == 0 ) {
        this.translate.use('ro');
      } else {
        this.translate.use('en');
      }
    });
  }

  ngOnInit(): void {
    // tslint:disable-next-line:max-line-length no-unused-expression
    (window as any).addEventListener('load', this.test, !1)  ? (window as any).addEventListener('load', this.initiateCall, !1) : this.initiateCall;
    // tslint:disable-next-line:max-line-length
    // window.addEventListener('load', this.test, !1) ? console.log('lalalallala') : console.log('trol');
    this.checkLanguage();
  }
}
