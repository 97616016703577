<div class="games-container">

  <div *ngIf="!isGameChosen; else startPlaying">
    <p>What do you want to play?</p>
    <button (click)="ChooseGame(1)">Never Have I Ever</button>
    <button (click)="ChooseGame(2)">Most Likely To</button>
  </div>

  <ng-template #startPlaying>

    <div *ngIf="gamePremises.length > 0; else gameOver">
      <p class="premise">
        {{ activePremise }}
      </p>
      <button (click)="nextPremise()"> Next</button>
    </div>

    <ng-template #gameOver>
      Game over!
    </ng-template>
  </ng-template>
</div>
