import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  @Input() loggedInUser: any;
  subscriptionId = 0;

  subscriptionAvailable = false;

  subscriptions = [
    {
      id: 1,
      name: 'subscriptie 1',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      price: 0,
      imgUrl: 'assets/other/svg/new/paintbrush.svg'
    },
    {
      id: 2,
      name: 'subscriptie 2',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      price: 22,
      imgUrl: 'assets/other/svg/new/paintpalet.svg'
    },
    {
      id: 3,
      name: 'subscriptie 3',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      price: 33,
      imgUrl: 'assets/other/svg/new/canvas.svg'
    },
    {
      id: 4,
      name: 'subscriptie 4',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      price: 44,
      imgUrl: 'assets/other/svg/new/dali.svg'
    }
  ];
  userSubscription = {
      id: 0,
      name: '',
      description: '',
      price: 0,
  };
  getUserSubscription(): void {
    this.subscriptionId = 1;
    this.subscriptions.forEach(subscription => {
      if (subscription.id === this.subscriptionId) {
        this.userSubscription = subscription;
      }
    });
  }
  ngOnInit(): void {
    if (this.subscriptionAvailable){
      this.getUserSubscription();
    }
  }

}
