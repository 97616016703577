<div class="home-container">

<!--  <h2>Automatic Slideshow</h2>-->

  <div *ngIf="events_planned">
    <h1 class="title">{{"joinUs" | translate}}</h1>
    <div class="upcoming-event" style="padding: 0 0 10% 0">
      <img src="assets/exhibition5/event-cover-short.jpg" alt="exhibition cover" class="vernisaj">
      <table>
        <tr>
          <td style="width: 50%">
            <h1 *ngIf="days_left === 0; else notToday">{{'todayIsTheSpecialDay' | translate}} <br> {{"areYouReady" | translate}} </h1>
            <ng-template #notToday>
              <h1 *ngIf="days_left === 1; else manyDays">{{"tomorrowIsTheSpecialDay" | translate}}</h1>
              <ng-template #manyDays>
                <h1 class="bold"><span class="red">{{days_left}}</span>{{"daysToGoUntill" | translate}}</h1>
              </ng-template>
            </ng-template>
            <div *ngIf="days_left === 0">
              <h3>{{'event5Details1' | translate}}</h3>
              <p>{{'event5Details2' | translate}}</p>
              <p>{{'event5Details3' | translate}}</p>

              <h3>{{'event5Details4' | translate}}</h3>
              <p>{{'event5Details5' | translate}}</p>
            </div>
            <div *ngIf="days_left !== 0">

              <h3>{{'event5Details1' | translate}}</h3>
              <p>{{'event5Details2' | translate}}</p>
              <p>{{'event5Details3' | translate}}</p>

              <h3>{{'event5Details4' | translate}}</h3>
              <p>{{'event5Details5' | translate}}</p>


            </div>

          </td>
          <td>
            <table class="in-tablfee">
              <tr>
                <td class="bold">{{"where" | translate}}</td>
                <td class="bold">{{"when" | translate}}</td>
              </tr>
              <tr>
                <td style="width: 20%">
                  <a
                    [href]="upcommingEvent.locationHref"
                    target="_blank">
                    <p> {{upcommingEvent.location}} </p>
                  </a>
                </td>
                <td style="width: 20%">
                  <p> 0{{upcommingEvent.day}}. 0{{upcommingEvent.month}}. {{upcommingEvent.year}}</p>
                  <p>(13:00 - 18:00)</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <br>
                  <button mat-raised-button class="simple-button" style="background: #c82020"><a href="#" routerLink="/Events">{{'seeMore' | translate}}</a></button>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="responsive-upcoming-event" *ngIf="events_planned">
      <img src="assets/exhibition5/event-cover-short.jpg" alt="exhibition cover" class="vernisaj">
      <table style="padding-top: 5%;">
        <tr>
          <td>
            <h1 *ngIf="days_left === 0; else notToday">{{"todayIsTheSpecialDay" | translate}}<br> {{"areYouReady" | translate}} </h1>
            <ng-template #notToday>
              <h1 *ngIf="days_left === 1; else manyDays">{{"tomorrowIsTheSpecialDay" | translate}}</h1>
              <ng-template #manyDays>
                <h1 class="bold">{{days_left}} {{"daysToGoUntill" | translate}}</h1>

              </ng-template>
            </ng-template>
            <div *ngIf="days_left === 0" class="padding">
              <h3>{{'event5Details1' | translate}}</h3>
              <p>{{'event5Details2' | translate}}</p>
              <p>{{'event5Details3' | translate}}</p>

              <h3>{{'event5Details4' | translate}}</h3>
              <p>{{'event5Details5' | translate}}</p>
            </div>
            <div *ngIf="days_left !== 0" class="padding">
              <h3>{{'event5Details1' | translate}}</h3>
              <p>{{'event5Details2' | translate}}</p>
              <p>{{'event5Details3' | translate}}</p>

              <h3>{{'event5Details4' | translate}}</h3>
              <p>{{'event5Details5' | translate}}</p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <table class="in-table">
              <tr>
                <td class="bold">{{"where" | translate}}</td>
                <td class="bold">{{"when" | translate}}</td>
              </tr>
              <tr>
                <td style="width: 20%">
                  <a
                    [href]="upcommingEvent.locationHref"
                    target="_blank">
                    <p>{{upcommingEvent.location}}</p>
                  </a>
                </td>
                <td style="width: 20%">
                  <p> 0{{upcommingEvent.day}}. 0{{upcommingEvent.month}}. {{upcommingEvent.year}} </p>
                  <p>(13:00 - 18:00)</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <br>
                  <button mat-raised-button class="simple-button" style="background: #c82020"><a href="#" routerLink="/Events">{{"seeMore" | translate}}</a></button>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <h1 class="title">{{'latestPainting' | translate }}</h1>
  <p class="seo-description">{{'latestDescription' | translate}}</p>
  <div class="latest-projects">
    <div class="latest-project-img-container" *ngFor="let painting of latestPaintings" (click)="goToPaintingDetails(painting.category, painting.id)">
      <img [src]="painting.path" alt="pictura acrilic pe diferite suprafete">
<!--      <p>{{painting.title}}</p>-->
      <div class="overlay-painting">
        <h2>{{'seeDetails' | translate}}</h2>
      </div>
    </div>
  </div>

<!--  <h1 class="title">{{'comingSoon' | translate}}</h1>-->
<!--  <img src="assets/me/cs1.jpg" class="market-img" id="coming-soon-desktop">-->
<!--  <img src="assets/me/csp1.png" class="market-img" id="coming-soon-mobile">-->
<!--  <button mat-raised-button (click)="goToMarket()" style="width: 100%; padding: 10px; text-align: center; background: #736552; color: white; text-transform: uppercase;">{{'wantToJoin'| translate}}</button>-->
<!--  <app-market></app-market>-->

<!--  <table class="market-table">-->
<!--    <tr>-->
<!--      <td>-->
<!--        <button mat-raised-button class="table-buttons">-->
<!--          Vreau sa stiu mai multe-->
<!--        </button>-->
<!--      </td>-->
<!--      <td>-->
<!--        <button mat-raised-button class="table-buttons">-->
<!--          Contul meu-->
<!--        </button>-->
<!--      </td>-->
<!--      <td>-->
<!--        <button mat-raised-button class="table-buttons">-->
<!--          Anunta-ma cand incepe-->
<!--        </button>-->
<!--      </td>-->
<!--    </tr>-->
<!--  </table>-->

  <h1 class="title">{{'exploreCategories' | translate}}</h1>
  <p class="seo-description">{{'exploreCategoriesDescription' | translate}}</p>
  <div class="categories">
        <div class="cat1" (click)="NavigateToGallery(1)">
          <p>{{'galleryCanvasPaintings' | translate}}</p>
          <img src ="../../assets/other/categories/panza.JPG" alt="categorie pictura - panza" class="cat-img">
        </div>
        <div class="cat2" (click)="NavigateToGallery(2)">
          <p>{{'galleryWallPaintings' | translate}}</p>
          <img src ="../../assets/other/categories/erete.jpg" alt="categorie pictura - perete"></div>
        <div class="cat4" (click)="NavigateToGallery(3)">
<!--          <p>{{'galleryMugPaintings' | translate}}</p>-->
          <p>{{'namePicture51' | translate}}</p>
          <img src ="../../assets/other/categories/martisoare.jpg" alt="categorie pictura - martisoare">
        </div>
        <div class="cat5" (click)="NavigateToGallery(4)">
          <p>{{'gallerySpecialGifts' | translate}}</p>
          <img src ="../../assets/other/categories/cadou.jpg" alt="categorie pictura - cadouri speciale">
        </div>
        <div class="cat6" (click)="NavigateToGallery(5)">
          <p>{{'galleryChristmas' | translate}}</p>
          <img src ="../../assets/other/categories/craciun.jpg" alt="categorie pictura - ornamente craciun">
        </div>

  </div>

  <h1 class="title" id="events">{{'events' | translate }}</h1>
  <div id="event4" class="event4-hero">
    <p class="p-header" style="text-align: left;">{{'event4Title' | translate}}</p>
    <p class="p-header" style="text-align: left;"> -11.06.2023- </p>
    <p class="p-text" style="text-align: left;">
      {{'event4Parahraph1' | translate}}<br>
      {{'event4Parahraph2' | translate}}<br>
      {{'event4Parahraph3' | translate}}<br>
    <div class="event-hero-button"  style="text-align: left;">
      <button mat-raised-button class="simple-button" (click)="NavigateToEvents(4)">{{'seeMore' | translate}}</button>
    </div>
  </div >

  <div id="event3" class="event3-hero">
    <p class="p-header" style="text-align: right;">{{'event3Title' | translate}}</p>
    <p class="p-header" style="text-align: right;"> -29.05.2021- </p>
    <p class="p-text" style="text-align: right;">
      {{'event3Parahraph1' | translate}}<br>
      {{'event3Parahraph2' | translate}}<br>
      {{'event3Parahraph3' | translate}}<br>
      {{'event3Parahraph4' | translate}}<br>
    <div class="event-hero-button">
      <button mat-raised-button class="simple-button" (click)="NavigateToEvents(3)">{{'seeMore' | translate}}</button>
    </div>
  </div >

  <div id="event1" class="event1-hero">
    <p class="p-header" style="text-align: left;">{{'event2Title' | translate }}</p>
    <p class="p-header" style="text-align: left;"> -01.06.2019- </p>
    <p class="p-text" style="text-align: left;">
      {{'event2Paragraph1' | translate }}<br>
      {{'event2Paragraph2' | translate }}<br>
      {{'event2Paragraph3' | translate }}<br>
      {{'event2Paragraph4' | translate }}<br>
    </p>
    <div class="event-hero-button" style="text-align: left;">
      <button mat-raised-button class="simple-button" (click)="NavigateToEvents(2)">{{'seeMore' | translate}}</button>
    </div>
  </div>

  <div id="event2" class="event2-hero">
    <p class="p-header" style="text-align: right;">{{'event1Title' | translate}}</p>
    <p class="p-header" style="text-align: right;"> -13.10.2018- </p>
    <p class="p-text" style="text-align: right;">
      {{'event1Paragraph1' | translate}}<br>
      {{'event1Paragraph2' | translate}}<br>
      {{'event1Paragraph3' | translate}}<br>
    <div class="event-hero-button">
      <button  mat-raised-button class="simple-button" (click)="NavigateToEvents(1)">{{'seeMore' | translate}}</button>
    </div>
  </div>

  <h1 class="hero-write">
   {{'hopeToSeeYou' | translate}}
  </h1>

  <app-contact></app-contact>
</div>
