<div class="edit-art-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
<!--  <h1>{{'yourArt' | translate}}</h1>-->
  <div *ngIf="loggedInUser.role_id === 3; else buyerOrArtist" style="padding: 2%; text-align: justify;">
   {{'artistAccountNotValidatedYet' | translate}}
  </div>
  <ng-template #buyerOrArtist>
    <div *ngIf="!isEditArtVisible">
      <!--    <table class="account-table" *ngIf="paintings.length > 0">-->
      <!--      <tr>-->
      <!--        <th>{{'artImage' | translate}}</th>-->
      <!--        <th class="hide-mobile">{{'artTitle' | translate}}</th>-->
      <!--        <th>{{'artActions' | translate}}</th>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td class="img_td">-->
      <!--          <img src="../../assets/gallery/37.jpg" >-->
      <!--        </td>-->
      <!--        <td class="hide-mobile">ksd sjai dhsiejsie</td>-->
      <!--        <td class="actions">-->
      <!--          <button mat-raised-button (click)="editArt(1)">{{'artEdit' | translate}}</button>-->
      <!--          <button mat-raised-button color="warn" (click)="openDialogDeleteObject(1)">{{'artDelete' | translate}}</button>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td class="img_td">-->
      <!--          <img src="../../assets/gallery/96.jpeg" >-->
      <!--        </td>-->
      <!--        <td class="hide-mobile">ksd sjai dhsiejsie</td>-->
      <!--        <td class="actions">-->
      <!--          <button mat-raised-button (click)="editArt(1)">{{'artEdit' | translate}}</button>-->
      <!--          <button mat-raised-button color="warn" (click)="openDialogDeleteObject(1)">{{'artDelete' | translate}}</button>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td class="img_td">-->
      <!--          <img src="../../assets/gallery/131.jpg" >-->
      <!--        </td>-->
      <!--        <td class="hide-mobile">ksd sjai dhsiejsie</td>-->
      <!--        <td class="actions">-->
      <!--          <button mat-raised-button (click)="editArt(1)">{{'artEdit' | translate}}</button>-->
      <!--          <button mat-raised-button color="warn" (click)="openDialogDeleteObject(1)">{{'artDelete' | translate}}</button>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td class="img_td">-->
      <!--          <img src="../../assets/gallery/76.png" >-->
      <!--        </td>-->
      <!--        <td class="hide-mobile">ksd sjai dhsiejsie</td>-->
      <!--        <td class="actions">-->
      <!--          <button mat-raised-button (click)="editArt(1)">{{'artEdit' | translate}}</button>-->
      <!--          <button mat-raised-button color="warn" (click)="openDialogDeleteObject(1)">{{'artDelete' | translate}}</button>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--    </table>-->
      <table class="account-table" *ngIf="paintings.length > 0">
        <tr>
          <th>{{'artImage' | translate}}</th>
          <th class="hide-mobile">{{'artTitle' | translate}}</th>
          <th>{{'artActions' | translate}}</th>
        </tr>
        <tr *ngFor="let painting of paintingsPag">
          <td class="img_td">
            <img [src]="backendAddress + painting.file_path" alt="{{painting.title | translate}}">
          </td>
          <td class="hide-mobile">{{painting.title}}</td>
          <td class="actions">
            <button mat-raised-button (click)="editArt(painting)">{{'artEdit' | translate}}</button>
            <button mat-raised-button color="warn" (click)="openDialogDeleteObject(painting)">{{'artDelete' | translate}}</button>
          </td>
        </tr>
      </table>
      <div class="paginator" *ngIf="paintings.length > 0">
        <button (click)="previousPage()" [ngClass]="currentPage == 0 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow down icon" style="transform: rotate(90deg);"> </button>
        <p>{{currentPage + 1}}</p>
        <button (click)="nextPage()" [ngClass]="currentPage == maxPages - 1 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow down icon" style="transform: rotate(-90deg);"> </button>
      </div>
    </div>

    <p *ngIf="paintings.length == 0" style="padding: 2%;">{{'noArt' | translate}}</p>

    <div style="padding: 2%" *ngIf="isEditArtVisible">
      <app-add-art *ngIf="isEditArtVisible"
                   [loggedInUser]="loggedInUser"
                   [isEditArtVisible]="isEditArtVisible"
                   [artObjectFromEdit]="toSendPainting"
                   (newItemEvent)="cancelEdit()"
      ></app-add-art>
    </div>
  </ng-template>
</div>
