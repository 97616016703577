<div class="gallery-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="menu-buttons" id="menu-galley">
    <button id="canvas" class="simple-button" (click)="PaintingCategory(1)">
      {{'galleryCanvasPaintings' | translate}}
    </button>
    <button id="wall" class="simple-button" (click)="PaintingCategory(2)">
      {{'galleryWallPaintings' | translate}}
    </button>
    <button id="gift" class="simple-button" (click)="PaintingCategory(4)">
      {{'gallerySpecialGifts' | translate}}
    </button>
    <button id="mug" class="simple-button" (click)="PaintingCategory(3)">
<!--      {{'galleryMugPaintings' | translate}}-->
      {{'namePicture51' | translate}}
    </button>
<!--    <button id="numbers" class="simple-button" (click)="PaintingCategory(7)">-->
<!--      {{'galleryHouseNumbers' | translate}}-->
<!--    </button>-->
    <button id="menus" class="simple-button" (click)="PaintingCategory(5)">
      {{'galleryChristmas' | translate}}
    </button>
  </div>

  <div id="responsive_p" class="resp_menu-buttons">
    <a [matMenuTriggerFor]="cathegorymenu">
      <p class="resp_p">
        {{ chosen_resp_category | translate}}
        <img src="assets/other/dotted-menu.svg" alt="mobile menu" class="cat-menu">
      </p>
    </a>
  </div>

  <mat-menu #cathegorymenu="matMenu">
    <button mat-menu-item id="resp_canvas" class="resp_simple-button" (click)="ResponsivePaintingCategory(1)"
            style="background-color: rgb(63,25,172)">{{'galleryCanvasPaintings' | translate}}
    </button>
    <button mat-menu-item id="resp_wall" class="resp_simple-button" (click)="ResponsivePaintingCategory(2)"
            style="background-color: rgb(63,25,172)">{{'galleryWallPaintings' | translate}}
    </button>
    <button mat-menu-item id="resp_gift" class="resp_simple-button" (click)="ResponsivePaintingCategory(4)"
            style="background-color: rgb(63,25,172)">{{'gallerySpecialGifts' | translate}}
    </button>
    <button mat-menu-item id="resp_mug" class="resp_simple-button" (click)="ResponsivePaintingCategory(3)"
            style="background-color: rgb(63,25,172)">
<!--      {{'galleryMugPaintings' | translate}}-->
      {{'namePicture51' | translate}}
    </button>
<!--    <button mat-menu-item id="resp_numbers" class="resp_simple-button" (click)="ResponsivePaintingCategory(7)"-->
<!--            style="background-color: rgb(63,25,172)">{{'galleryHouseNumbers' | translate}}-->
<!--    </button>-->
    <button mat-menu-item id="resp_menu" class="resp_simple-button" (click)="ResponsivePaintingCategory(5)"
            style="background-color: rgb(63,25,172)">
      {{'galleryChristmas' | translate}}
    </button>
  </mat-menu>

  <div class="short-category-description">
    <p *ngIf="paintingCathegory === 1">
      {{'canvasCategoryShortDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 2">
      {{'wallCategoryShortDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 4">
      {{'specialCategoryShortDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 3">
      {{'martisoareCategoryShortDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 5">
      {{'christmasCategoryShortDescription' | translate}}
    </p>
  </div>

  <div class="paginator" *ngIf="maxPages > 1">
    <button (click)="previousPage()" [ngClass]="currentPage == 0 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(90deg);"> </button>

    <p class="disabled" *ngIf="currentPage === 0"></p>
    <p class="disabled" *ngIf="currentPage > 0">{{currentPage}}</p>
    <p>{{currentPage + 1}}</p>
    <p class="disabled" *ngIf="currentPage + 2 <= maxPages">{{currentPage + 2}}</p>
    <p class="disabled" *ngIf="currentPage === maxPages - 1"></p>

    <button (click)="nextPage()" [ngClass]="currentPage == maxPages - 1 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(-90deg);"> </button>
  </div>

<!--  <div class="colors-explained">-->
<!--    <div class="available"></div>{{'available' | translate}}-->
<!--    <div class="not-available"></div>{{'onOrder' | translate}}-->
<!--  </div>-->

  <div class="categories">
    <div class="painting" *ngFor="let painting of paintings" [routerLink]="['/Painting', painting.category, painting.id]">
<!--      <div [ngClass]="{'available': painting.availability == '1',-->
<!--                        'not-available': painting.availability == '2'}">available</div>-->
      <img loading="lazy" src="{{painting.path}}" alt="{{painting.name | translate}}">
      <div class="overlay-painting">
<!--        <div [ngClass]="{'available': painting.availability == 1, 'not-available' : painting.availability == 0 }"></div>-->
        <p>{{painting.name | translate}}</p>
      </div>
    </div>
  </div>

  <div class="paginator" *ngIf="maxPages > 1">
    <button (click)="previousPage()" [ngClass]="currentPage == 0 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(90deg);"> </button>

    <p class="disabled" *ngIf="currentPage === 0"></p>
    <p class="disabled" *ngIf="currentPage > 0">{{currentPage}}</p>
    <p>{{currentPage + 1}}</p>
    <p class="disabled" *ngIf="currentPage + 2 <= maxPages">{{currentPage + 2}}</p>
    <p class="disabled" *ngIf="currentPage === maxPages - 1"></p>

    <button (click)="nextPage()" [ngClass]="currentPage == maxPages - 1 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(-90deg);"> </button>
  </div>

  <div class="long-category-description">
    <p *ngIf="paintingCathegory === 1">
      {{'canvasCategoryLongDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 2">
      {{'wallCategoryLongDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 4">
      {{'specialCategoryLongDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 3">
      {{'martisoareCategoryLongDescription' | translate}}
    </p>
    <p *ngIf="paintingCathegory === 5">
      {{'christmasCategoryLongDescription' | translate}}
    </p>
  </div>
</div>

