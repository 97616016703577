import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {PaintingDetailsComponent} from '../painting-details/painting-details.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  constructor(private router: Router, public dialog: MatDialog) { }
  GoToHomepage(): void{
    this.router.navigate(['']);
  }
  StayInTheWoods(): void{
    // @ts-ignore
    document.getElementById('btn-home').style.display = 'none';
    // @ts-ignore
    document.getElementById('btn-woods').style.display = 'none';
    // @ts-ignore
    document.getElementById('lost-text').style.display = 'none';
    // @ts-ignore
    document.getElementById('error-code').style.display = 'none';
    // @ts-ignore
    document.getElementById('btn-home-tablet').style.display = 'none';
    // @ts-ignore
    document.getElementById('btn-woods-tablet').style.display = 'none';
    // @ts-ignore
    document.getElementById('lost-text-tablet').style.display = 'none';
    // @ts-ignore
    document.getElementById('error-code-tablet').style.display = 'none';
    // @ts-ignore
    document.getElementById('btn-home-mobile').style.display = 'none';
    // @ts-ignore
    document.getElementById('btn-woods-mobile').style.display = 'none';
    // @ts-ignore
    document.getElementById('lost-text-mobile').style.display = 'none';
    // @ts-ignore
    document.getElementById('error-code-mobile').style.display = 'none';
    let dialogRef: any;
    dialogRef = this.dialog.open(PaintingDetailsComponent, {
      panelClass: 'custom-dialog-container',
      disableClose: true
    });
    dialogRef.componentInstance.isEvents = false;
  }
  ngOnInit(): void {
    localStorage.setItem('latestPainting', '');
  }

}
