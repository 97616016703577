<div class="add-art-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div *ngIf="loggedInUser.role_id === 3; else buyerOrArtist" style="text-align: justify;">
    {{'artistAccountNotValidatedYet' | translate}}
  </div>
  <ng-template #buyerOrArtist>
    <div *ngIf="!canAddArt">
      {{'youReachedMaximumNrOfAddedArt' | translate}}
    </div>
    <div *ngIf="canAddArt">
      <div *ngFor="let artObject of artObjects" class="art-object">

        <div class="form-container">
          <form #f="ngForm" (ngSubmit)="onSubmitform(f)">

            <div class="upload">
              <button mat-raised-button type="button" (click)="fileInput.click()" *ngIf="artObject.image.name === ''">
                <span>{{'uploadImage' | translate}}</span>
                <input #fileInput type="file" (change)="fileEvent(artObject, $event)" style="display:none;" accept="image/png, image/jpg, image/jpeg" />
              </button>
              <label *ngIf="artObject.image.name !== ''">{{artObject.image.name}}</label>

              <img src="assets/other/cancel.svg" alt="cancel icon" class="cancel-icon"
                   (click)="openDialogDeleteImage(artObject)" *ngIf="artObject.image.name !== '' && !isEditArtVisible">
            </div>

            <div class="field-container">
              <div class="field">
                <label for="{{artObject.title}}">{{'addArtTitle' | translate}}</label>
                <input name="title" type="text" [value]="artObject.title" [(ngModel)]="artObject.title">
              </div>

              <div class="field">
                <label for="{{artObject.description}}">{{'addArtDescription' | translate}}</label>
                <input name="desc" type="text" [value]="artObject.description" [(ngModel)]="artObject.description">
              </div>

              <div class="field">
                <label for="category-{{artObject.id}}">{{'addArtCategory' | translate}}</label>
                <select name="category" [ngModel]="artObject.category" (change)="changeCategory(artObject.id, $event)">
                  <option *ngFor="let category of categories" [value]="category.id">{{category.name | translate}}</option>
                </select>
              </div>

              <div class="field">
                <label for="availability-{{artObject.id}}">{{'addArtAvailability' | translate}}</label>
                <select name="availability" [ngModel]="artObject.availability" (change)="changeAvailability(artObject.id, $event)">
                  <option *ngFor="let availability of availabilities" [value]="availability.id">{{availability.name | translate}}</option>
                </select>
              </div>

              <div class="field">
                <label for="{{artObject.dimension}}">{{'addArtDimension' | translate}}</label>
                <input name="dim" type="text" [value]="artObject.dimension" [(ngModel)]="artObject.dimension">
              </div>

              <div class="field">
                <label for="{{artObject.technique}}">{{'addArtTechnique' | translate}}</label>
                <input name="tech" type="text" [value]="artObject.technique" [(ngModel)]="artObject.technique">
              </div>

              <div class="field">
                <label for="{{artObject.year}}">{{'addArtYear' | translate}}</label>
                <input name="year" type="text" [value]="artObject.year" [(ngModel)]="artObject.year">
              </div>
            </div>
            <p class="error">{{errorMessage}}</p>
            <button mat-raised-button>{{'saveArt' | translate}}</button>
            <button mat-raised-button color="warn" *ngIf="isEditArtVisible" style="margin-top: 10px" (click)="cancelEdit()">{{'cancelArt' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

<!--  <h1 *ngIf="!isEditArtVisible">{{'addArt' | translate}}</h1>-->
</div>
