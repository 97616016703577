import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import { backendAddress } from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  constructor(private api: ConfigService, public location: Location, private router: Router,
              private snackBar: MatSnackBar, private translate: TranslateService, private recaptchaV3Service: ReCaptchaV3Service ) { }

  message = '';
  errorMessage = '';
  email = '';
  fullName = '';
  phone = '';
  offer = '';
  additionalText = '';
  isFormVisible = true;
  paintingPath = '';
  paintingId = '';
  priceDisplayed = '';
  isLoaderVisible = false;
  href = '';
  termsConditions = false;
  agreeData = false;
  userId = null;
  isUserLoggedIn = false;
  artObject: any;
  artist: any;
  areFieldsValid(): boolean {
    if (this.offer === ''  || !this.offer) {
      this.offer = 'no-price';
    }
    if ((this.isUserLoggedIn && (this.fullName !== '') ) ||
      (!this.isUserLoggedIn && (this.fullName !== '' && this.email !== '' &&
      this.agreeData && this.termsConditions))) {
      this.errorMessage = '';
      return true;
    } else {
      this.translate.get('completeRequiredFields').subscribe(message => {
        this.errorMessage = message;
        return false;
      });
    }
    return false;
  }
  sendEmails(): void {
    let message = '';
    console.log('art', this.artObject);
    message = 'Oferta a fost pusa pe: ' + '\"' + this.artObject.title + '\"' +
      '(' + this.artObject.description + ')' + ' de dimensiunea: ' + this.artObject.dimension + ', din anul ' + this.artObject.year;
    this.api.SendMultipleEmails(this.artist.email, this.email, this.fullName, message,
      this.artist.full_name, this.fullName).subscribe(data => {
    }, err => {
      // tslint:disable-next-line:no-shadowed-variable
      this.translate.get('errorOccurred').subscribe(message => {
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'error-snack-bar',
            horizontalPosition: 'end', verticalPosition: 'top'});
      });
    });
    // this.translate.get('sendMailArtist').subscribe(artist => {
    //   this.translate.get('sendMailReceivedOffer').subscribe(received => {
    //     this.translate.get('sendMailFrom').subscribe(from => {
    //     });
    //   });
    // });
  }
  sendOffer(): void {
    if ((!this.isUserLoggedIn && this.isEmailValid()) || this.isUserLoggedIn){
      if (this.areFieldsValid()) {
        this.isFormVisible = false;
        this.isLoaderVisible = true;
        const postBody = {user_id: this.userId, email: this.email, name: this.fullName, phone_number: this.phone,
          offer: this.offer, details: this.additionalText, status: 1, art_object_id: Number(this.paintingId)};
        if (this.href === '/Offer'){
          this.api.postOfferCreate(postBody).subscribe((data: any) => {
            this.translate.get('offerSentSuccessfully').subscribe(message => {
                this.message = message;
                this.sendEmails();
                this.isLoaderVisible = false;
              });
            },
            error => {
              this.translate.get('errorOccurred').subscribe(message => {
                this.message = message;
                this.isLoaderVisible = false;
              });
            });
        } else {
          this.api.SendEmail(this.fullName, this.email, JSON.stringify(postBody)).subscribe(
            data => {
              this.isLoaderVisible = false;
            },
            error => {
              this.translate.get('errorOccurred').subscribe(message => {
                this.errorMessage = message;
                this.isLoaderVisible = false;
              });
            });
        }
      }
    }
  }
  send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submitContactForm')
      .subscribe((token: string) => {
          // console.log(`Token [${token}] generated`);
          this.sendOffer();
        },
        error => {
          console.log('error', error);
          this.snackBar.open('Recaptcha is not working properly', '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        });
  }

  getDisplayedPrice(): void {
    // if painting has last price -> last offer 'Ultima oferta este de:'
    // else floor price for category 'Pretul minim pentru categorie este de: '
    // this.priceDisplayed = 'NU STIU DACA RAMANE (influenta proasta..ish) Ultima oferta este de:';
  }
  isEmailValid(): boolean {
    if (this.email.indexOf('@') !== -1 && this.email.indexOf('.') !== -1 ) {
      this.errorMessage = '';
      return true;
    } else {
      this.translate.get('enterValidMail').subscribe(message => {
        this.errorMessage = message;
      });
      return false;
    }
  }

  getUserDetails(): void {
    this.api.getLoggedUser().subscribe(user => {
     if (user) {
       // @ts-ignore
       this.email = user.email;
       // @ts-ignore
       this.fullName = user.full_name;
       // @ts-ignore
       this.phone = user.phone_number;
       // @ts-ignore
       this.userId = user.id;
       // @ts-ignore
       this.isUserLoggedIn = true;
     }
    }, err => {
      // this.snackBar.open('Nu exista user logat', '',
      // {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
      // @ts-ignore
      this.email = '';
      // @ts-ignore
      this.fullName = '';
      // @ts-ignore
      this.phone = '';
      // @ts-ignore
      this.userId = '';
      // @ts-ignore
      this.isUserLoggedIn = false;
    });
  }

  getArtistDetails(): void {
    this.isLoaderVisible = true;
    this.api.getUserById(this.artObject.user_id.toString()).subscribe(data => {
      this.artist = data;
      this.paintingPath = this.artObject.file_path;
      this.paintingId = this.artObject.id;
      if (this.href === '/Offer'){
        this.paintingPath = backendAddress.value + this.paintingPath;
      }
      this.getUserDetails();
      this.isLoaderVisible = false;
    }, err => {
      this.translate.get('noUserFound').subscribe(message => {
        this.snackBar.open(message, '',
          {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        this.isLoaderVisible = false;
      });
    });
  }

ngOnInit(): void {
    this.href = this.router.url;
    // @ts-ignore
    this.artObject = JSON.parse(localStorage.getItem('artObject'));
    this.getArtistDetails();
    // this.getDisplayedPrice();
  }

}
