import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UpcomingEventService {
  public state = false;
  public day = 2;
  public month = 6;
  public year = 2024;
  public location = 'Turtha Grill & Bar, Sibiu';
  public locationHref = 'https://www.google.com/maps/place/Turtha+Grill%26Bar/@45.7881937,24.1428317,17z/data=!3m1!4b1!4m6!3m5!1s0x474c6719140ddf11:0xfe715dea4f8a7e22!8m2!3d45.7881901!4d24.1477026!16s%2Fg%2F11gmcptjx6';

  // tslint:disable-next-line:typedef
  getDaysInMonth(year: any, month: any) {
    return new Date(year, month, 0).getDate();
  }

  // tslint:disable-next-line:typedef
  public getDaysUntiillEvent() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const today = date.getDate();
    let daysLeft = 0;

    if (this.month === month) {
      daysLeft = this.day - today;
    } else {
      const daysInCurrentMonth = this.getDaysInMonth(2024, month);
      daysLeft = daysInCurrentMonth - today + this.day;
    }

    return daysLeft;
  }

  // tslint:disable-next-line:typedef
  public getHoursUntillEvent() {
    // const date = new Date();
    // const month = date.getMonth() + 1;
    // const today = date.getDate();
    // //    // Date(ywar 2024, month may 4, day 8, hour 12, minute 30, seconds 45, miliseconds 500);
    // const date1 = new Date(2024, 5, 2, 13, 0, 0);
    // const date2 = new Date(2024, month, today, date.getHours(), date.getMinutes(), date.getSeconds());
    // // @ts-ignore
    // const hourss = Math.floor(Math.abs(date1 - date2) / 36e5);
    // console.log('hours', hourss);


    // const countDownDate = new Date('Jan 5, 2030 15:37:25').getTime();
    // // tslint:disable-next-line:only-arrow-functions typedef
    // setInterval(function() {
    //
    //   // Get today's date and time
    //   const now = new Date().getTime();
    //
    //   const distance = countDownDate - now;
    //
    //   // Time calculations for days, hours, minutes and seconds
    //   const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //   const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //   const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //   const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //
    //   console.log('aaaa', days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ');
    //
    //   if (distance < 0) {
    //     // clearInterval(x);
    //     // @ts-ignore
    //     console.log('bbbb', days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ');
    //   }
    // }, 1000);
  }
}
