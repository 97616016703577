<div class="my-offer-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div *ngIf="loggedInUser.role_id === 3; else buyerOrArtist" style="padding: 2%; text-align: justify;">
    {{'artistAccountNotValidatedYet' | translate}}
  </div>
  <ng-template #buyerOrArtist>
    <!--  <h1>{{'myOffers' | translate}}</h1>-->
    <table class="account-table" *ngIf="offers.length > 0">
      <tr>
        <th>
          {{'art' | translate}}
        </th>
        <!--      <th>-->
        <!--        {{'price' | translate}}-->
        <!--      </th>-->
        <th *ngIf="loggedInUser.role_id === 2">
          {{'offerUser' | translate}}
        </th>
        <th class="hide-mobile">
          {{'offerDate' | translate}}
        </th>
        <th>
          {{'state' | translate}}
        </th>
      </tr>
      <tr *ngFor="let offer of offersPag">
        <td class="img-td">
          <img [src]="backendAddress + offer.art_object.file_path" alt="oferte primite" class="img-offer">
        </td>
        <!--      <td>-->
        <!--        <span *ngIf="offer.offer === 'no-price'">{{'noAllocatedBudget' | translate}}</span>-->
        <!--        <span *ngIf="offer.offer !== 'no-price'">{{offer.offer}}</span>-->
        <!--      </td>-->
        <td *ngIf="loggedInUser.role_id === 2">
          <button mat-raised-button (click)="seeBuyerDetails(offer)">{{'details' | translate}}</button>
        </td>
        <td class="hide-mobile">
          {{offer.updated_at}}
        </td>
        <td *ngIf="loggedInUser.role_id === 2">
          <!--      <select [ngModel]="offer.status" name="state" (change)="changeOfferState(offer, $event)" class="transparent-select">-->
          <select name="state" [ngModel]="offer.status" class="transparent-select"
                  id="selectid-{{offer.id}}"
                  (change)="changeOfferState(offer, $event)"
                  [ngClass]="'state-' + offer.status">
            <option *ngFor="let state of states" [value]="state.id">{{state.name | translate}}</option>
          </select>
        </td>
        <td *ngIf="loggedInUser.role_id == 1 && offer.status === 1" class="pending-td">
          {{'statePending' | translate}}
        </td>
        <td *ngIf="loggedInUser.role_id == 1 && offer.status === 2" class="approved-td">
          {{'stateApproved' | translate}}
        </td>
      </tr>
    </table>
    <div class="paginator" *ngIf="offers.length > 0">
      <button (click)="previousPage()" [ngClass]="currentPage == 0 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(90deg);"> </button>
      <p>{{currentPage + 1}}</p>
      <button (click)="nextPage()" [ngClass]="currentPage == maxPages - 1 ? 'disabled' : ''"> <img src="assets/other/arrow-down-sign-to-navigate.svg" alt="arrow icon" style="transform: rotate(-90deg);"> </button>
    </div>
    <p *ngIf="offers.length === 0" style="padding: 2%;">{{'noOffers' | translate}}</p>
  </ng-template>
</div>
