import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Paintings} from '../shared/Paintings';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-seasonal-objects',
  templateUrl: './seasonal-objects.component.html',
  styleUrls: ['./seasonal-objects.component.scss']
})
export class SeasonalObjectsComponent implements OnInit {

  constructor(private api: ConfigService, private translate: TranslateService) { }

  // tslint:disable-next-line:ban-types
  objects: any = [
    {
      id: 148,
      path: 'assets/gallery/148.jpg',
      name: 'namePicture148',
      category: '5',
      tags: 'woodAcrylic',
      availability: 0,
      price: '1',
      dimension: '5-7cm',
      description: 'descriptionPicture148',
      created_at: null,
      updated_at: null
    }
  ];
  isLoaderVisible = false;

  filerByAvailability(): void {
    const availableItems: any[] = [];
    const unavailableItems: any[] = [];
    // @ts-ignore
    this.objects.forEach((object: any) => {
      if (object.availability === 0) {
        availableItems.push(object);
      } else {
        unavailableItems.push(object);
      }
    });

    this.objects = availableItems;
    unavailableItems.forEach(element => {
      this.objects.push(element);
    });
  }

  getPaintings(): void {
    this.api.GetPaintings('5').subscribe(
      data => {
        // @ts-ignore
        this.objects = data;
        this.filerByAvailability();
        this.isLoaderVisible = false;
      },
      error => {
        console.log('oops', error);
        this.isLoaderVisible = false;
      }
    );
  }
  seeDetails(id: any): void {
    console.log('see datils');
  }

  ngOnInit(): void {
    this.isLoaderVisible = true;
    this.getPaintings();
  }

}
