import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EventNumberService {
  // tslint:disable-next-line:variable-name
  private event_Subject = new BehaviorSubject(0);
  // tslint:disable-next-line:variable-name
  current_event = this.event_Subject.asObservable();

  constructor(){}

  // tslint:disable-next-line:typedef variable-name
  update_eventNumber(new_eventNumber: number){
    this.event_Subject.next(new_eventNumber);
  }
}
