import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-event-details-page',
  templateUrl: './event-details-page.component.html',
  styleUrls: ['./event-details-page.component.scss']
})
export class EventDetailsPageComponent implements OnInit {

  constructor(public location: Location) { }
  @Input() event = {
    title: '',
    details: '',
    location: '',
    date: '',
    urls: ''
  };
  urls = [];
  details = [];
  ngOnInit(): void {
    this.urls = JSON.parse(this.event.urls);
    this.details = JSON.parse(this.event.details);
  }

}
