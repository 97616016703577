import {Component, Input, OnInit} from '@angular/core';
import {Paintings} from '../shared/Paintings';
import {PopupComponent} from '../popup/popup.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConfigService} from '../config/config.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { backendAddress } from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-edit-art',
  templateUrl: './edit-art.component.html',
  styleUrls: ['./edit-art.component.scss']
})

export class EditArtComponent implements OnInit {
  router: Router;

  constructor(public dialog: MatDialog, private privateRouter: Router, private api: ConfigService,
              private snackBar: MatSnackBar, private translate: TranslateService) {
    this.router = privateRouter;
  }

  @Input() loggedInUser: any;

  isLoaderVisible = false;
  paintings = [
    {
      id: 0,
      title: '',
      description: '',
      picture_category_id: 0,
      availability: 0,
      dimension: '',
      technique: '',
      year: 0,
      file_name: '',
      user_id: 0,
      created_at: '',
      updated_at: '',
      file_path: '',
    }
  ];
  isEditArtVisible = false;
  toSendPainting = [];
  paintingsPag = [
    {
      id: 0,
      title: '',
      description: '',
      picture_category_id: 0,
      availability: 0,
      dimension: '',
      technique: '',
      year: 0,
      file_name: '',
      user_id: 0,
      created_at: '',
      updated_at: '',
      file_path: '',
    }
  ];
  areFiltersVisible = true;
  isUserLoggedIn = false;
  currentPage = 0;
  maxPages = 0;
  paintingsPerPage = 5;
  firstPaintingIndex = 0;
  backendAddress = backendAddress.value;
  resetPagination(): void{
    this.currentPage = 0;
    this.maxPages = 0;
    this.paintingsPerPage = 12;
    this.firstPaintingIndex = 0;
    this.initiatePages();
  }
  initiatePages(): void {
    this.paintingsPag = this.paintings;
    this.maxPages = Math.round(this.paintings.length / this.paintingsPerPage + 0.5);
    this.firstPaintingIndex = this.paintings[0].id;
    const paintingsPerPage = [
      {
        id: 0,
        title: '',
        description: '',
        picture_category_id: 0,
        availability: 0,
        dimension: '',
        technique: '',
        year: 0,
        file_name: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
        file_path: '',
      }
    ];
    let ct = 0;
    this.paintings.forEach(painting => {
      if (ct < this.paintingsPerPage){
        // @ts-ignore
        paintingsPerPage.push(painting);
        ct++;
      }
    });
    this.paintingsPag = paintingsPerPage;
    this.paintingsPag.shift();
  }
  nextPage(): void {
    if (this.currentPage < this.maxPages - 1 ) {
      this.paintingsPag = this.paintings;
      this.currentPage++;
      this.reindexPaintings();
    }
  }

  previousPage(): void {
    if (this.currentPage > 0){
      this.currentPage--;
      this.paintingsPag = this.paintings;
      this.reindexPaintings();
    }
  }
  reindexPaintings(): void {
    // this.is_painting_selected = false;
    const paintingsPerPage = [
      {
        id: 0,
        title: '',
        description: '',
        picture_category_id: 0,
        availability: 0,
        dimension: '',
        technique: '',
        year: 0,
        file_name: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
        file_path: '',
      }
    ];
    let ct = this.paintingsPerPage;

    for (let i = this.currentPage * this.paintingsPerPage; i <= this.paintingsPag.length - 1; i++){
      if (ct > 0){
        paintingsPerPage.push(this.paintingsPag[i]);
        ct--;
      }
    }
    this.paintingsPag = paintingsPerPage;
    this.paintingsPag.shift();
    window.scroll(0, 0);
  }
  getArtObjects(): void {
    this.isLoaderVisible = true;
    this.api.getLoggedInArtistArtObjects().subscribe(data => {
        // @ts-ignore
        this.paintings = data.reverse();
        if (this.paintings.length > 0){
          this.initiatePages();
        }
        this.isLoaderVisible = false;
      },
      error => {
        console.log('getLoggedInUserArtObjects error', error);
        this.isLoaderVisible = false;
      });
  }

  deletePainting(painting: Paintings): void{
    this.isLoaderVisible = true;
    this.api.deleteArtObject(String(painting)).subscribe(data => {
        this.getArtObjects();
        this.isLoaderVisible = false;
        this.translate.get('objectSuccessfullyDeleted').subscribe(message => {
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'success-snack-bar',
              horizontalPosition: 'end', verticalPosition: 'top'});
        });
      },
      error => {
        console.log('something went wrong error', error);
        this.translate.get('errorOccurred').subscribe(message => {
          this.isLoaderVisible = false;
          this.snackBar.open(message, '',
            {duration: 3000, panelClass: 'error-snack-bar',
              horizontalPosition: 'end', verticalPosition: 'top'});
        });
      });
  }

  openDialogDeleteObject(artObject: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {popupText: 'Esti sigur ca vrei sa stergi obiectul?', popupButtonsNotVsible: 'nu'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.deletePainting(artObject.id);
      }
    });
  }
  editArt(painting: any): void {
    this.isEditArtVisible = true;
    this.toSendPainting = painting;
  }
  cancelEdit(): void {
    this.isEditArtVisible = false;
  }
  ngOnInit(): void {
    this.getArtObjects();
  }

}
