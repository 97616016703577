<div class="my-account-container" id="my-account-container">
  <app-loader *ngIf="isLoaderVisible"></app-loader>
  <div  *ngIf="user">
    <div class="hello-mate">
      <p>{{'welcome' | translate}} {{user.full_name}}!</p>
    </div>

    <div class="account-container">
      <div class="top-nav">
        <button mat-raised-button [matMenuTriggerFor]="accountmenu" class="resp-menu">
          {{selectedName}}
          <img src="assets/other/dotted-menu.svg" alt="mobile menu" class="cat-menu">
        </button>

        <mat-menu #accountmenu="matMenu">
          <button mat-menu-item *ngFor="let sideNavComponent of sideNavComponents" (click)="changeContent(sideNavComponent.id, sideNavComponent.name)"
                  id="menu{{sideNavComponent.id}}{{selectedId}}" style="background-color: rgba(40, 167, 122, 0.3); color: white">
            {{sideNavComponent.name}}
          </button>
        </mat-menu>

      </div>
      <div class="side-nav">
        <p *ngFor="let sideNavComponent of sideNavComponents" (click)="changeContent(sideNavComponent.id, sideNavComponent.name)" id="menu{{sideNavComponent.id}}{{selectedId}}">{{sideNavComponent.name}}</p>
      </div>
      <div class="main-account">
        <div *ngIf="offers">
          <app-my-offers [loggedInUser]="user"></app-my-offers>
        </div>
        <div *ngIf="addArt" class="padding">
          <app-add-art [loggedInUser]="user"></app-add-art>
        </div>
        <div *ngIf="editArt">
          <app-edit-art [loggedInUser]="user"></app-edit-art>
        </div>
        <div *ngIf="settings" class="padding">
          <app-account-settings [loggedInUser]="user"></app-account-settings>
        </div>
        <div *ngIf="payment" class="padding">
          <app-subscription [loggedInUser]="user"></app-subscription>
        </div>
        <div *ngIf="termsConditions">
          <app-terms-conditions></app-terms-conditions>
        </div>
      </div>
    </div>
  </div>
</div>
