import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-painting-details',
  templateUrl: './painting-details.component.html',
  styleUrls: ['./painting-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaintingDetailsComponent implements OnInit {

  constructor(private router: Router, public dialog: MatDialog,  public dialogRef: MatDialogRef<PaintingDetailsComponent>) { }
  isEvents = false;
  goHome(): void{
    this.router.navigate(['']);
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }

}
